import React, { useState } from "react";
import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CustomModal from "../../../../components/UI/CustomModal/Index";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";
import { Roles } from "../../../../constants/roles";

const TableButtons = ({ data, category, title }) => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  const { user } = useUser();

  const getRowId = () => {
    const rowData = {};
    data.api
      .getAllColumns()
      .filter((col) => col.field !== "__check__" && !!col)
      .forEach(
        (col) => (rowData[col.field] = data.getValue(data.id, col.field))
      );
    return rowData.id;
  };

  const toDetail = (e) => {
    e.stopPropagation();
    const id = getRowId();
    navigate(`/${category}/detail/${id}`);
  };

  const barberAdd = (e) => {
    e.stopPropagation();
    const id = getRowId();
    navigate(`/${category}/add-barber/${id}`);
  };

  const toEdit = (e) => {
    e.stopPropagation();
    const id = getRowId();
    navigate(`/${category}/edit/${id}`);
  };

  const toDelete = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Agregar Barbero" placement="top">
        <IconButton onClick={barberAdd}>
          <PersonAddAlt1Icon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Detalles" placement="top">
        <IconButton onClick={toDetail}>
          <InfoIcon />
        </IconButton>
      </Tooltip>

      <Tooltip title="Editar" placement="top">
        <IconButton onClick={toEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      {user.role !== Roles.Seller && (
        <Tooltip title="Eliminar" placement="top">
          <IconButton onClick={toDelete}>
            <DeleteIcon />
            {open && (
              <CustomModal
                id={getRowId()}
                open={open}
                setOpen={setOpen}
                category={category}
                title={title}
              ></CustomModal>
            )}
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
};

export default TableButtons;
