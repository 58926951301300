import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import { WeekMock, ShiftsMocks } from "../../../../services/rates.services";
import { CustomButton } from "../../../../components/UI/CustomButton";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { getRatesDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

const getDay = (rate) => {
  const day = WeekMock.filter((day) => day.value === rate.dayWeek);
  return day[0].title;
};

const getShift = (rate) => {
  const shift = ShiftsMocks.filter((shift) => shift.value === rate.partDay);
  return shift[0].title;
};

export const RatesDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  // eslint-disable-next-line no-unused-vars
  const [rate, setRate] = useState({});

  const viewHandlers = {
    getRateSuccess(data) {
      console.log("<<<<<<DETAIL RATE >>>>>>>", data.statusText);
      setRate(data.data.data[0]);
    },
    getRateError(err) {
      console.log("<<<<<<DETAIL RATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getRateById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="rates-detail-page">
      {JSON.stringify(rate) !== "{}" && (
        <div className="rates-detail-main-container">
          <TableContainer
            component={Paper}
            className="rates-detail-table-container"
          >
            <Table className="rates-detail-table">
              <TableHead className="rates-detail-table-header">
                <TableCell
                  align="center"
                  colSpan={2}
                  className="rates-detail-table-title"
                >
                  Detalle de la tarifa
                </TableCell>
              </TableHead>
              <TableBody className="rates-detail-table-body">
                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Día:{" "}
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {getDay(rate)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Turno:
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {getShift(rate)}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Tipo de trabajo:
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {rate.typeCut}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Bajada de bandera:
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {`$${rate.flag}`}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Intervalo (tiempo):
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {`${rate.interval} segundos`}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Precio de intervalo:
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {`$${rate.intervalPrice}`}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="rates-detail-cell-title">
                    Precio máximo:
                  </TableCell>
                  <TableCell className="rates-detail-cell">
                    {`$ ${rate.maximum}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
      <div className="rates-detail-back-button-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default RatesDetail;
