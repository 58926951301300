import axios from "axios";

export const HttpCreateBarberGateway = () => {
  return {
    createBarber: async (data) => {
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/register/barber`,
        data
      );
    },
  };
};
