import React, { useState } from "react";
import "./styles.scss";
import useUser from "../../../../hooks/useUser";
import { CustomButton } from "../../../../components/UI/CustomButton";
import {
  Divider,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { DropzoneComponent } from "react-dropzone-component";
import UploadIcon from "@mui/icons-material/Upload";

const ModifyQR = () => {
  const { user } = useUser();
  // eslint-disable-next-line no-unused-vars
  const [dropzone, setDropzone] = useState(null);

  const djsConfig = {
    autoProcessQueue: false,
    addRemoveLinks: true,
    acceptedFiles: "image/jpeg,image/png,image/gif",
    dictDefaultMessage:
      "Arrastre y suelte un archivo para subir o haga click aqui!",
    paramName: "image",
    parallelUploads: 100,
    maxFiles: 100,
    dictRemoveFile: "Eliminar",
  };

  const componentConfig = {
    postUrl: `${process.env.REACT_APP_BASE_URL}/activities/upload/id/`,
  };

  const eventHandlers = {
    init: (dz) => setDropzone(dz),
    drop: (file) => {
      console.log("--------------- Dropped ---------------", file);
    },
    addedfile: (file) => {
      /* console.log("DROPZONE: ", dropzone); */
      console.log("--------------- added ---------------", file);
    },
    success: (file) => {
      console.log("--------------- success ---------------", file);
    },
    removedfile: (file) => {
      console.log("--------------- remove ---------------", file);
    },
  };

  const handlePost = (evt) => {
    console.log("Post QR", evt);
    /* dropzone.processQueue(); */
  };

  return (
    <div className="modify-qr-screen">
      <Grid container className="modify-qr-grid">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="modify-qr-grid-item"
        >
          <Typography
            variant="h4"
            className="modify-qr-item-title"
            gutterBottom
          >
            Subir código QR
          </Typography>
          <Divider
            sx={{
              mb: 2,
              width: "90%",
            }}
            variant="middle"
            color={"#ffc107"}
          />
          <div className="modify-qr-dz-container">
            <DropzoneComponent
              config={componentConfig}
              eventHandlers={eventHandlers}
              djsConfig={djsConfig}
            />
            <Stack
              direction={"row"}
              spacing={1}
              className="upload-cut-btns-container"
              justifyContent="space-evenly"
            >
              <CustomButton
                onClick={(evt) => handlePost(evt)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
                icon={<UploadIcon />}
              >
                Subir
              </CustomButton>
            </Stack>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="modify-qr-grid-item"
        >
          <div className="modify-qr-table-container">
            <Typography
              variant="h4"
              className="modify-qr-item-title"
              gutterBottom
            >
              Información de perfil
            </Typography>
            <Divider
              sx={{
                mb: 2,
                width: "90%",
              }}
              variant="middle"
              color={"#ffc107"}
            />
            <TableContainer component={Paper} className="modify-qr-table">
              <Table className="modify-qr-barber-table">
                <TableHead className="barber-data-table-header">
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="barber-username"
                  >
                    {user.username}
                  </TableCell>
                </TableHead>
                <TableBody className="barber-data-table-body">
                  <TableRow>
                    <TableCell className="barber-data-cell-title">
                      Email:
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="barber-data-cell-title">
                      Nombre de usuario:
                    </TableCell>
                    <TableCell>{user.username}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="barber-data-cell-title">
                      Rol:
                    </TableCell>
                    <TableCell>{user.role}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="barber-data-cell-title">
                      Comisión?:
                    </TableCell>
                    <TableCell>{`${user.commission} %`}</TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="barber-data-cell-title">
                      QR Actual:
                    </TableCell>
                    <TableCell>Acá va un img con el código qr</TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default ModifyQR;
