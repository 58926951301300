import React, { useState } from "react";
import "./styles.scss";
import { Box, Grid, Tab, Tabs, Typography } from "@mui/material";
import PropTypes from "prop-types";
import CardData from "../../components/PerformanceCard";

const TabPanel = (props) => {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

const a11yProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const PerformanceHome = () => {
  const [value, setValue] = useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="performance-screen">
      <Typography variant="h4" component="div" sx={{ mb: 2 }}>
        Rendimiento
      </Typography>
      <div className="performance-screen-content-container">
        <Grid container spacing={2} className="performance-screen-grid">
          <Grid
            item
            className="performance-grid-item"
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Typography
              variant="h6"
              component="div"
              sx={{ mb: 3, fontFamily: "Roboto" }}
            >
              Rendimiento del día
            </Typography>
            <CardData />
          </Grid>
          <Grid
            item
            className="performance-grid-item-monthly"
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Box sx={{ width: "100%" }} className="tabs-container">
              <Tabs
                value={value}
                onChange={handleChange}
                textColor="title"
                indicatorColor="primary"
                aria-label="secondary tabs example"
                variant="fullWidth"
                scrollButtons="auto"
                className='performance-tabs'
              >
                <Tab label="Mes Anterior" {...a11yProps(0)} />
                <Tab label="Mes actual" {...a11yProps(1)} />
              </Tabs>
              <TabPanel
                value={value}
                index={0}
                className="performance-tab-panel"
              >
                <CardData />
              </TabPanel>
              <TabPanel
                value={value}
                index={1}
                className="performance-tab-panel"
              >
                <CardData />
              </TabPanel>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default PerformanceHome;
