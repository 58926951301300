import React, { useEffect } from "react";
import "./styles.scss";
import { Box, ButtonBase, Divider, styled, Typography } from "@mui/material";
import LoginHeader from "../../components/LoginHeader/index";
import owner from "../../../../assets/images/owner.jpg";
import barber from "../../../../assets/images/barber.jpg";
import seller from "../../../../assets/images/seller.jpg";
import { useNavigate } from "react-router-dom";
import useUser from "../../../../hooks/useUser";

const images = [
  {
    img: owner,
    url: "login/owner",
    title: "Dueño",
    width: "32.8%",
  },
  {
    img: barber,
    url: "login/barber",
    title: "Barbero",
    width: "32.8%",
  },
  {
    img: seller,
    url: "login/seller",
    title: "Vendedor",
    width: "32.8%",
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 220,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important",
    height: 100,
    marginTop: "3%",
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.25,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.65,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export const LoginSelector = () => {
  const navigate = useNavigate();
  const { setAnchorEl } = useUser();

  useEffect(() => {
    setAnchorEl(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNavigate = (url) => {
    let type = url.split("/");
    navigate(type[0], { state: { type: type[1] } });
  };

  return (
    <div className="ls-main-screen">
      <LoginHeader />
      <div className="ls-screen-title">
        <Typography variant="h5" className="ls-title">
          Selecciona un perfil
        </Typography>
      </div>
      <Box className="ls-login-buttons-container">
        {images.map((image) => {
          return (
            <ImageButton
              focusRipple
              key={image.title}
              style={{ width: image.width }}
              onClick={() => handleNavigate(image.url)}
            >
              <ImageSrc style={{ backgroundImage: `url(${image.img})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="h5"
                  color="inherit"
                  sx={{
                    position: "relative",
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(2)} + 6px)`,
                  }}
                >
                  {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
          );
        })}
      </Box>
      <div className="create-account-button-container">
        <Divider
          variant="middle"
          color="#FFC107"
          sx={{ marginTop: "1%", marginBottom: "1%" }}
        />
        {/* <div className="create-account-button">
          <CustomButton
            onClick={() => navigate("/singin")}
            variant="text"
            color="#FFC107"
            textColor={"black"}
          >
            Crear cuenta
          </CustomButton>
        </div> */}
      </div>
    </div>
  );
};

export default LoginSelector;
