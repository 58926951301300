export const ValorizationHomePresenter = (
  getAllBarbersGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Valorization screen was rendered!");
    },
    getAllBarbers() {
      const getAllBarbersPromise = getAllBarbersGateway.getAllBarbers();

      getAllBarbersPromise
        .then((data) => {
          viewHandlers.getBarbersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarbersError(err);
        });
    },
  };
};
