import React, { useEffect } from "react";
import "./styles.scss";
import { Grid } from "@mui/material";
import { PieChart } from "./../../../../components/UI/PieChart/PieChart";
import { useState } from "react";
import { getGlobalSummaryPresenter } from "../../infrastructure/presentation/presenterProvider";
import useUser from "../../../../hooks/useUser/index";
import RangeDatePicker from "../../components/RangeDatePicker";

const GlobalSummary = ({ branchOffice }) => {
  const { user } = useUser();
  const [fromDate, setFromDate] = useState(
    new Date().toLocaleDateString().split("/").reverse().join("-")
  );
  const [toDate, setToDate] = useState(
    new Date().toLocaleDateString().split("/").reverse().join("-")
  );

  const viewHandlers = {
    getCustomersByGenderSuccess(data) {
      console.log("Ejecuta esto: ", data);
    },
    getCustomersByGenderError(err) {
      console.log("err", err);
    },
  };

  const presenter = getGlobalSummaryPresenter(viewHandlers);

  const queryData = {
    owner_id: user.id_user,
    from_date: fromDate,
    to_date: toDate,
    id: branchOffice.id,
  };

  useEffect(() => {
    presenter.present();
    presenter.getCustomersByGender(queryData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  return (
    <div className="global-summary-screen">
      <Grid container className="global-summary-grid">
        <Grid item xs={12} sm={12} className="global-summary-grid-item">
          <div className="global-summary-dates-container">
            <RangeDatePicker setFromDate={setFromDate} setToDate={setToDate} />
          </div>
        </Grid>
        <Grid item xs={12} sm={12} className="global-summary-grid-item">
          <PieChart
            title={"División de clientes por género"}
            labels={["Hombres", "Mujeres", "Otro"]}
            scores={[10, 8, 3]}
            legend={true}
            tooltips={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default GlobalSummary;
