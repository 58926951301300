export const BarbersEditPresenter = (
  getBarberWithQrGateway,
  updateBarberGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Barber Edit was rendered");
    },
    getBarberById(id) {
      const getBarberByIdPromise = getBarberWithQrGateway.getBarberWithQR(id);

      getBarberByIdPromise
        .then((data) => {
          viewHandlers.getBarberByIdSucces(data);
        })
        .catch((err) => {
          viewHandlers.getBarberByIdError(err);
        });
    },
    updateBarber(data) {
      const updateBarberPromise = updateBarberGateway.updateBarber(data);

      updateBarberPromise
        .then((data) => {
          viewHandlers.updateBarberSuccess(data);
        })
        .catch((err) => {
          viewHandlers.updateBarberError(err);
        });
    },
  };
};

/* getBarber(id) {
      const getBarberPromise = getBarberGateway.getBarber(id);

      getBarberPromise
        .then((data) => {
          viewHandlers.getBarberSucces(data);
        })
        .catch((error) => viewHandlers.getBarberError(error));
    }, */
/* 
    getBarberById(id) {
      const getBarberPromise = getBarberGateway.getBarber(id);

      getBarberPromise
        .then((data) => {
          viewHandlers.getBarberUpdSucces(data);
        })
        .catch((error) => {
          viewHandlers.getBarberUpdError(error);
        });
    }, */

// const handleUpdateClick = () => {
//   updateData(1, { name: 'Nuevo nombre' })
//     .then(response => {
//       // maneja la respuesta del servidor
//     })
//     .catch(error => {
//       // maneja el error
//     });
// }

/* updateBarber(data) {
      const updateBarberPromise = updateBarberGateway.updateBarber(data);

      updateBarberPromise
        .then((data) => {
          viewHandlers.updateBarberSucces(data);
        })
        .catch((err) => viewHandlers.updateBarberError(err));
    },
    getBarber(id) {
      const getBarberPromise = getBarberGateway.getBarber(id);

      getBarberPromise
        .then((data) => {
          viewHandlers.getBarberSucces(data);
        })
        .catch((error) => viewHandlers.getBarberError(error));
    }, */
