import React, { useState, useEffect } from "react";
import "./styles.scss";
import DataTable from "../../../../components/DataTable";
import { getBarberShopsHomePresenter } from "../../infrastructure/presentation/presenterProvider";
import TableButtons from "../../components/TableButtons";
const columns = [
  {
    field: "id",
    headerName: "id",
    width: 40,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "owner_id",
    headerName: "Dueño",
    flex: 1,
    minWidth: 80,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Barbería",
    flex: 1,
    minWidth: 100,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "branch_offices_address_id",
    headerName: "Dirección",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Acciones",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <TableButtons
          data={params}
          category={"barbershops"}
          title={"Barbería"}
        />
      );
    },
  },
];

export const BarberShopsHome = () => {
  const [barbershops, setBarberShops] = useState([]);
  const viewHanlders = {
    getAllBarberShopsSuccess(data) {
      console.log("<<<<<<ALL BARBERSHOPS>>>>>>>", data.statusText);
      setBarberShops(data.data.data);
    },
    getAllBarberShopsError(error) {
      console.log("<<<<<<ERROR>>>>>>>", error);
    },
  };

  const presenter = getBarberShopsHomePresenter(viewHanlders);

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"bsh-main-screen"}>
      <div className={"bsh-screen-title"}>Barberías</div>
      <DataTable
        rows={barbershops}
        columns={columns}
        buttonTitle={"Nueva Barbería"}
        category={"barbershops"}
      />
    </div>
  );
};

export default BarberShopsHome;
