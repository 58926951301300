import React, { useEffect } from "react";
import PercentIcon from "@mui/icons-material/Percent";
import {
  Box,
  FormHelperText,
  Grid,
  Input,
  Slider,
  Typography,
} from "@mui/material";
import "./styles.scss";

const CommissionSlider = ({ commission, setCommission, error, setError }) => {
  const handleSlider = (event, newValue) => {
    setCommission(newValue);
    setError(false);
  };

  const handleSliderInput = (evt) => {
    setCommission(evt.target.value === "" ? "" : Number(evt.target.value));
    setError(false);
  };

  const handleBlur = () => {
    if (commission < 0) {
      setCommission(0);
    } else if (commission > 100) {
      setCommission(100);
    }
  };

  useEffect(() => {
    console.log("Error");
  }, [error]);

  return (
    <Box className="commission-slider-main-container">
      <Typography id="input-slider" className="commission-slider-title">
        Porcentaje de comisión
      </Typography>
      <Grid container spacing={2} alignItems={"center"}>
        <Grid item>
          <PercentIcon />
        </Grid>
        <Grid item xs>
          <Slider
            className={error && "commission-slider-error"}
            valueLabelDisplay="auto"
            value={typeof commission === "number" ? commission : 0}
            onChange={handleSlider}
            aria-labelledby="input-slider"
          />
        </Grid>
        <Grid item>
          <Input
            className={error && "commission-slider-error"}
            value={commission}
            size="small"
            onChange={handleSliderInput}
            onBlur={handleBlur}
            inputProps={{
              step: 5,
              min: 0,
              max: 100,
              type: "number",
              "aria-labelledby": "input-slider",
            }}
          />
        </Grid>
      </Grid>
      {error && (
        <FormHelperText className={"error-helper-text"}>
          {"Debe seleccionar el porcentaje de la comisión"}
        </FormHelperText>
      )}
    </Box>
  );
};

export default CommissionSlider;
