import axios from "axios";

export const HttpGetBarberShopGateway = () => {
  return {
    getBarberShop: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/branch-office/get/id/${id}`
      );
    },
  };
};
