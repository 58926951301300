export const CustomerCreatePresenter = (createClientGateway, viewHandlers) => {
  return {
    present() {
      console.log("CustomerCreate was rendered!");
    },
    createClient(data) {
      const createClientPromise = createClientGateway.createClient(data);

      createClientPromise
        .then((res) => {
          viewHandlers.createClientSuccess(res);
        })
        .catch((err) => {
          viewHandlers.createClientError(err);
        });
    },
  };
};
