import React from "react";
import { Button } from "@mui/material";

export function CustomButton({
  children,
  onClick,
  color,
  textColor,
  variant,
  icon,
  hoverColor,
}) {
  const outlined = {
    backgroundColor: "transparent",
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    textTransform: "Uppercase",
    textDecoration: "none",
    minHeight: "7vh",
    maxHeight: "8vh",
    minWidth: 150,
    maxWidth: 150,
    color: textColor,
    border: `2px solid ${color}`,
    borderRadius: 3,
    "&:hover": {
      border: `2px solid ${color}`,
      boxShadow:
        "rgba(0, 0, 0, 0.25) 0px 10px 20px, rgba(0, 0, 0, 0.32) 0px 6px 6px",
    },
    "&:active": {
      boxShadow: "none",
    },
  };

  const filled = {
    backgroundColor: color,
    boxShadow:
      "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    textTransform: "Uppercase",
    textDecoration: "none",
    minHeight: "7vh",
    maxHeight: "8vh",
    minWidth: 150,
    maxWidth: 150,
    color: textColor,
    border: "none",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: hoverColor,
      boxShadow:
        "rgba(0, 0, 0, 0.25) 0px 10px 20px, rgba(0, 0, 0, 0.32) 0px 6px 6px",
      border: "none",
    },
    "&:active": {
      boxShadow: "none",
      border: "none",
    },
  };

  const text = {
    backgroundColor: "transparent",
    textTransform: "Uppercase",
    textDecoration: "none",
    minHeight: "7vh",
    maxHeight: "8vh",
    minWidth: 150,
    maxWidth: 150,
    color: textColor,
    border: "none",
    borderRadius: 3,
    "&:hover": {
      backgroundColor: "#FFDB6E",
      boxShadow:
        "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
    },
    "&:active": {
      boxShadow: "none",
    },
  };

  const tinyText = {
    marginBottom: "1%",
    backgroundColor: "transparent",
    textDecoration: "underline",
    textTransform: "none",
    minHeight: "5vh",
    maxHeight: "7vh",
    minWidth: 150,
    maxWidth: 200,
    color: textColor,
    border: "none",
    borderRadius: 3,
    "&:hover": {
      textDecoration: "none",
    },
    "&:active": {
      boxShadow: "none",
    },
  };

  return (
    <Button
      onClick={onClick}
      size={variant === "tiny" ? "500" : "medium"}
      sx={
        (variant === "outlined" && outlined) ||
        (variant === "filled" && filled) ||
        (variant === "text" && text) ||
        (variant === "tiny" && tinyText)
      }
      startIcon={icon !== undefined && icon}
    >
      {children}
    </Button>
  );
}

export function SuccessButton({ children, onClick }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: "#19B981",
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        textTransform: "Uppercase",
        textDecoration: "none",
        minHeight: "7vh",
        maxHeight: "8vh",
        minWidth: 150,
        color: "#FFFFFF",
        border: "none",
        borderRadius: 3,
        "&:hover": {
          backgroundColor: "#16A573",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 10px 20px, rgba(0, 0, 0, 0.32) 0px 6px 6px",
        },
        "&:active": {
          boxShadow: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}

export function ErrorButton({ children, onClick }) {
  return (
    <Button
      onClick={onClick}
      sx={{
        backgroundColor: "#CE3A3A",
        boxShadow:
          "rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px",
        textTransform: "Uppercase",
        textDecoration: "none",
        minHeight: "7vh",
        maxHeight: "8vh",
        minWidth: 150,
        color: "#FFFFFF",
        border: "none",
        borderRadius: 3,
        "&:hover": {
          backgroundColor: "#BA1C1C",
          boxShadow:
            "rgba(0, 0, 0, 0.25) 0px 10px 20px, rgba(0, 0, 0, 0.32) 0px 6px 6px",
        },
        "&:active": {
          boxShadow: "none",
        },
      }}
    >
      {children}
    </Button>
  );
}
