/* Gateways */
import { HttpLoginGateway } from "../gateways/HttpLoginGateway";
import { HttpRegisterGateway } from "../gateways/HttpRegisterGateway";

/* Presenters */
import { LoginPresenter } from "./LoginPresenter";
import { RegisterPresenter } from "./RegisterPresenter";

export const getLoginPresenter = (viewHandlers) => {
  const getLoginGateway = HttpLoginGateway();
  return LoginPresenter(getLoginGateway, viewHandlers);
};

export const getRegisterPresenter = (viewHandlers) => {
  const getRegisterGateway = HttpRegisterGateway();
  return RegisterPresenter(getRegisterGateway, viewHandlers);
};
