import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import RatesForm from "../../../../components/Forms/RatesForm";
import { getRatesEditPresenter } from "../../infrastructure/presentation/presenterProvider";

export const RatesEdit = () => {
  const { id } = useParams();
  const [rate, setRate] = useState({});
  const [barberShops, setBarberShops] = useState([]);

  const viewHandlers = {
    getAllBarberShopsSuccess(data) {
      console.log("<<<<<<ALL BARBERSHOPS>>>>>>", data.statusText);
      let barberShopsList = data.data.data;
      setBarberShops(
        barberShopsList.map((item) => ({
          value: item.id,
          title: item.name,
        }))
      );
    },
    getAllBarberShopsError(err) {
      console.log("<<<<<<BARBERSHOPS ERROR>>>>>>>", err);
    },
    getRateSuccess(data) {
      console.log("<<<<<<GET RATE>>>>>>", data.statusText);
      setRate(data.data.data[0]);
    },
    getRateError(err) {
      console.log("<<<<<<GET ERROR>>>>>>>", err);
    },
    updateRateSuccess(data) {
      console.log("<<<<<<UPDATE RATE>>>>>>", data.statusText);
    },
    updateRateError(err) {
      console.log("<<<<<<UPDATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesEditPresenter(viewHandlers);

  const editRate = (data) => {
    presenter.updateRate(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
    presenter.getRateById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rates-edit-screen">
      <div className="rates-edit-form-container">
        <RatesForm
          id={id}
          update={editRate}
          updRate={rate}
          barberShops={barberShops}
        />
      </div>
    </div>
  );
};

export default RatesEdit;
