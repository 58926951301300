import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import {
  Avatar,
  Box,
  FormControl,
  FormHelperText,
  InputAdornment,
  InputLabel,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

const searchCriteria = (text, searchText) => {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const BarbersSelector = ({ barbers, setSelectedBarber, error, setError }) => {
  const [searchText, setSearchText] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [open, setOpen] = useState(false);

  const optionsToShow = useMemo(
    () =>
      barbers &&
      barbers.filter((option) =>
        searchCriteria(
          `${option.name} ${option.lastname} ${option.document_number}`,
          searchText
        )
      ),
    [barbers, searchText]
  );

  const generateOptions = () => {
    return optionsToShow.map((option, index) => {
      return (
        <MenuItem key={index} value={option}>
          <ListItemAvatar>
            <Avatar>
              <ContentCutIcon />
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={`${option.name} ${option.lastname}`}
            secondary={`DNI: ${option.document_number}`}
          />
          <ListItemIcon>
            <AddCircleOutlineIcon />
          </ListItemIcon>
        </MenuItem>
      );
    });
  };

  const addBarber = (barber) => {
    setError(false);
    setSelectedOption(
      `${barber.name} ${barber.lastname} | DNI: ${barber.document_number}`
    );
    setOpen(false);
    setSelectedBarber(barber);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchText("");
    setOpen(false);
  };

  useEffect(() => {
    console.log("Error");
  }, [error]);

  return (
    <Box className="barbers-selector-main-container">
      <FormControl
        fullWidth
        className="barbers-selector-form-control"
        error={error}
      >
        <InputLabel id="barbers-selector-search-label">Barberos</InputLabel>
        <Select
          className="barbers-selector-select-component"
          MenuProps={{ autoFocus: false }}
          labelId="barbers-selector-search-label"
          open={open}
          id="barbers-selector"
          value={selectedOption}
          label="Options"
          onChange={(e) => addBarber(e.target.value)}
          onOpen={handleOpen}
          onClose={handleClose}
          renderValue={() => selectedOption}
        >
          <ListSubheader>
            <TextField
              size="small"
              autoFocus
              placeholder="Escriba para buscar..."
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSearchText(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
          {generateOptions()}
        </Select>
        {error && (
          <FormHelperText className={"error-helper-text"}>
            {"Debe seleccionar un barbero para enviar el formulario"}
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default BarbersSelector;
