import React, { useMemo } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import "./sytles.scss";
import { Pie } from "react-chartjs-2";

ChartJS.register(ArcElement, Tooltip, Legend);

export const PieChart = ({ title, labels, scores, tooltips, legend }) => {
  const data = useMemo(
    function () {
      return {
        labels: labels,
        datasets: [
          {
            data: scores,
            backgroundColor: [
              "rgba(153, 204, 255, 0.7)",
              "rgba(255, 204, 255, 0.7)",
              "rgba(204, 255, 204, 0.7)",
              "rgba(254, 255, 143, 0.7)",
              "rgba(255, 204, 153, 0.7)",
              "rgba(255, 204, 204, 0.7)",
              "rgba(255, 153, 204, 0.7)",
              "rgba(204, 153, 255, 0.7)",
              "rgba(204, 204, 255, 0.7)",
              "rgba(204, 255, 255, 0.7)",
              "rgba(153, 255, 204, 0.7)",
              "rgba(204, 255, 153, 0.7)",
            ],
            borderColor: [
              "rgb(0, 0, 0)",
              /* "rgb(153, 204, 255)",
              "rgb(255, 204, 255)",
              "rgb(204, 255, 204)",
              "rgb(254, 255, 143)",
              "rgb(255, 204, 153)",
              "rgb(255, 204, 204)",
              "rgb(255, 153, 204)",
              "rgb(204, 153, 255)",
              "rgb(204, 204, 255)",
              "rgb(204, 255, 255)",
              "rgb(153, 255, 204)",
              "rgb(204, 255, 153)", */
            ],
            borderWidth: 1,
            alignItems: "center",
          },
        ],
      };
    },
    [labels, scores]
  );

  const options = {
    fill: true,
    animations: true,
    responsive: true,
    plugins: tooltips
      ? {
          tooltip: {
            callbacks: {
              title: (context) => {
                return `${context[0].label}`;
              },
              label: (context) => {
                return `Total cortes: ${context.formattedValue}`;
              },
              beforeFooter: (context) => {
                return "Efectivo: $";
              },
              afterFooter: (context) => {
                return "Digital: $";
              },
            },
          },
          legend: {
            display: legend,
          },
          title: {
            padding: 10,
            display: true,
            text: title,
            color: "#1e1e1e",
            font: {
              size: 22,
              family:
                "system-ui,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,sans-serif",
            },
          },
        }
      : {
          legend: {
            display: legend,
          },
          title: {
            padding: 10,
            display: true,
            text: title,
            color: "#1e1e1e",
            font: {
              size: 22,
              family:
                "system-ui,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,sans-serif",
            },
          },
        },
    maintainAspectRatio: false,
  };

  return (
    <div className="pie-chart-container">
      <div className="pie-chart-inner-container">
        <Pie data={data} options={options} className="pie-chart" />
      </div>
    </div>
  );
};

/* export const GenderPieChart = ({ title, labels, scores, tooltips, legend }) => {
  const genderData = {
    labels,
    datasets: [
      {
        label: "Cantidad",
        data: scores,
        backgroundColor: [
          "rgba(153, 204, 255, 0.45)",
          "rgba(255, 204, 255, 0.45)",
          "rgba(204, 255, 204, 0.45)",
        ],
        borderColor: [
          "rgb(153, 204, 255)",
          "rgb(255, 204, 255)",
          "rgb(204, 255, 204)",
        ],
        borderWidth: 1,
        alignItems: "center",
      },
    ],
  };

  const genderOptions = {
    fill: true,
    animations: true,
    responsive: true,
    plugins: tooltips
      ? {
          tooltip: {
            callbacks: {
              title: (context) => {
                return `${context[0].label}`;
              },
              label: (context) => {
                return `Total cortes: ${context.formattedValue}`;
              },
              beforeFooter: (context) => {
                return "Efectivo: $";
              },
              afterFooter: (context) => {
                return "Digital: $";
              },
            },
          },
          legend: {
            display: legend,
          },
          title: {
            padding: 10,
            display: true,
            text: title,
            color: "#1e1e1e",
            font: {
              size: 22,
              family:
                "system-ui,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,sans-serif",
            },
          },
        }
      : {
          legend: {
            display: legend,
          },
        },
    maintainAspectRatio: false,
  };

  return (
    <div className="pie-chart-container">
      <div className="pie-chart-inner-container">
        <Pie data={genderData} options={genderOptions} className="pie-chart" />
      </div>
    </div>
  );
};
 */
