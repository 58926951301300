export const RegisterPresenter = (getRegisterGateway, viewHandlers) => {
  return {
    present() {
      console.log("Register screen was rendered");
    },
    registerBarber(data) {
      const registerPromise = getRegisterGateway.registerBarber(data);

      registerPromise
        .then((data) => {
          viewHandlers.registerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.registerError(err);
        });
    },
    registerOwner(data) {
      const registerPromise = getRegisterGateway.registerOwner(data);

      registerPromise
        .then((data) => {
          viewHandlers.registerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.registerError(err);
        });
    },
    registerSeller(data) {
      const registerPromise = getRegisterGateway.registerSeller(data);

      registerPromise
        .then((data) => {
          viewHandlers.registerSuccess(data);
        })
        .catch((err) => {
          viewHandlers.registerError(err);
        });
    },
  };
};
