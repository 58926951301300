import React, { useEffect, useState } from "react";
import "./styles.scss";
import DataTable from "../../../../components/DataTable";
import { getBarbersHomePresenter } from "../../infrastructure/presentation/presenterProvider";
import TableButtons from "../../components/TableButtons";

const columns = [
  {
    field: "id",
    headerName: "id",
    flex: 0.5,
    minWidth: 80,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 180,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "lastname",
    headerName: "Apellido",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "document_number",
    headerName: "DNI",
    flex: 1,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 250,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Acciones",
    flex: 1,
    minWidth: 250,
    headerClassName: "column-title",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <TableButtons data={params} category={"barbers"} title={"Barberos"} />
      );
    },
  },
];

export const BarbersHome = () => {
  const [barbers, setBarbers] = useState([]);

  const viewHandlers = {
    getAllBarbersSucces(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
      setBarbers(data.data.data);
    },
    getAllBarbersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarbersHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllBarbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={"barbers-home-main-screen"}>
      <div className={"barbers-home-screen-title"}>Barberos</div>
      <DataTable
        rows={barbers}
        columns={columns}
        buttonTitle={"Nuevo Barbero"}
        category={"barbers"}
      />
    </div>
  );
};

export default BarbersHome;
