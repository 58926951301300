import React from "react";
import { useLocation } from "react-router-dom";
import "./styles.scss";
import { Card, CardContent, Grid, Typography } from "@mui/material";

export const BillsDetail = () => {
  const { state } = useLocation();
  const { queryData } = state;
  // eslint-disable-next-line no-unused-vars
  const { owner, fromDate, toDate } = queryData;

  return (
    <div className="bills-detail-screen-container">
      <div className="bills-detail-title-container">
        <Typography
          variant="h2"
          gutterBottom
          component="div"
          className="bills-detail-title"
        >
          {owner.fullname}
        </Typography>
      </div>
      <div className="bills-detail-main-container">
        <Grid
          container
          spacing={{ xs: 6, sm: 8, md: 2 }}
          className="bills-detail-main-grid"
        >
          <Grid item xs={12} md={3} className={"bills-detail-grid-item"}>
            <Card className={"grid-item-data-container"} elevation={3}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  Usuario desde
                </Typography>
                <Typography variant="body1">
                  {new Date(owner.contract_date).toLocaleDateString()}
                </Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} md={3} className={"bills-detail-grid-item"}>
            <Card className={"grid-item-data-container"} elevation={3}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  Ciudad
                </Typography>
                <Typography variant="body1">{owner.locality}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid ite xs={12} md={3} className={"bills-detail-grid-item"}>
            <Card className={"grid-item-data-container"} elevation={3}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  Telefono
                </Typography>
                <Typography variant="body1">{owner.phone}</Typography>
              </CardContent>
            </Card>
          </Grid>
          <Grid ite xs={12} md={3} className={"bills-detail-grid-item"}>
            <Card className={"grid-item-data-container"} elevation={3}>
              <CardContent>
                <Typography variant="h5" component="div" sx={{ mb: 2 }}>
                  Email
                </Typography>
                <Typography variant="body1">{owner.email}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default BillsDetail;
