import axios from "axios";

export const HttpGetAllBranchOffices = () => {
  return {
    getAllbranchOffices: async () => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/branch-office/get-all`
      );
    },
  };
};
