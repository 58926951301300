import React from "react";
import "../../../styles/FormStyles/styles.scss";
import { Box, Divider, Grid, Stack } from "@mui/material";
import CustomTextField from "../../UI/CustomTextInput";
import CustomSelect from "../../UI/CustomSelect";
import { CustomButton } from "../../UI/CustomButton";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

const selectOptions = [
  {
    title: "Masculino",
    value: "male",
  },
  {
    title: "Femenino",
    value: "female",
  },
  {
    title: "Otro",
    value: "other",
  },
];

const CustomerForm = ({ create }) => {
  const { handleSubmit, control, reset } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    create(data);
    navigate("/home");
  };

  const resetForm = () => {
    reset({});
  };

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">Registrar cliente</h3>
      </div>
      <Divider
        color="#FFC107"
        variant="middle"
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label="Nombre"
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="lastname"
                control={control}
                label="Apellido"
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"email"}
                control={control}
                label={"Email"}
                type={"email"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "El correo ingresado no es válido",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"document_number"}
                control={control}
                label={"DNI"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                  minLength: {
                    value: 7,
                    message: "Ingrese un número de documento válido",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} lg={4} className="custom-form-grid-item-xs">
              <CustomTextField
                name={"age"}
                control={control}
                label={"Edad"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} lg={4} className="custom-form-grid-item-xs">
              <CustomSelect
                name={"gender"}
                control={control}
                label={"Género"}
                options={selectOptions}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={resetForm}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  Registrar
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default CustomerForm;
