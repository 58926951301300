import React, { useState, useEffect } from "react";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import "../../../styles/FormStyles/styles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";

import CustomTextField from "../../UI/CustomTextInput";
import { CustomButton } from "../../UI/CustomButton";

const OwnerForm = ({ id, ownerObject, create, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    ownerObject === undefined
      ? create({
          ...data,
          contract_date: new Date()
            .toLocaleDateString()
            .split("/")
            .reverse()
            .join("-"),
        })
      : update({ ...data, id: Number(id) });
    navigate("/owners/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    if (ownerObject !== undefined) {
      setValue("fullname", ownerObject.fullname);
      setValue("document_number", ownerObject.document_number);
      setValue("email", ownerObject.email);
      setValue("password", ownerObject.password);
      setValue("comment", ownerObject.comment);
      setValue("address", ownerObject.address);
      setValue("locality", ownerObject.locality);
      setValue("province", ownerObject.province);
      setValue("country", ownerObject.country);
      setValue("commission", ownerObject.commission);
      setValue("phone", ownerObject.phone);
    }
  }, [setValue, ownerObject]);

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">
          {ownerObject === undefined ? "Crear dueño" : "Editar dueño"}
        </h3>
      </div>
      <Divider
        variant="middle"
        color="#FFC107"
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="fullname"
                control={control}
                label="Nombre y Apellido"
                value={ownerObject !== undefined ? ownerObject.fullname : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="document_number"
                control={control}
                label="DNI"
                value={ownerObject !== undefined ? ownerObject.dni : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label="Email"
                value={ownerObject !== undefined ? ownerObject.email : ""}
                type={"email"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                value={ownerObject !== undefined ? ownerObject.password : ""}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: false,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="comment"
                control={control}
                label="Comentario"
                value={ownerObject !== undefined ? ownerObject.comment : ""}
                type={"text"}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="address"
                control={control}
                label="Dirección"
                value={ownerObject !== undefined ? ownerObject.address : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="locality"
                control={control}
                label="Localidad"
                value={ownerObject !== undefined ? ownerObject.locality : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="province"
                control={control}
                label="Provincia"
                value={ownerObject !== undefined ? ownerObject.province : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="country"
                control={control}
                label="País"
                value={ownerObject !== undefined ? ownerObject.country : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="commission"
                control={control}
                label="Comisión"
                value={ownerObject !== undefined ? ownerObject.commission : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="phone"
                control={control}
                label="Telefono"
                value={ownerObject !== undefined ? ownerObject.phone : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={resetForm}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {ownerObject === undefined ? "Crear" : "Actualizar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default OwnerForm;
