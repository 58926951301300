import axios from "axios";

export const HttpCreateBarberShopGateway = () => {
  return {
    createBarberShop: async (data) => {
      const { owner_id, name, phone, address, locality, province, country } =
        data;
      const body = {
        owner_id,
        name,
        phone,
        password: "",
        address,
        locality,
        province,
        country,
      };
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/branch-office/create`,
        body
      );
    },
  };
};
