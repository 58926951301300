import React, { useState, useEffect } from "react";
import { Grid, Stack, Box, Divider } from "@mui/material";
import "../../../styles/FormStyles/styles.scss";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import CustomTextField from "../../UI/CustomTextInput";
import { CustomButton } from "../../UI/CustomButton";
import UploadButton from "../../UI/CustomFileUploadButton/index";

const BarberForm = ({ id, barber, create, update }) => {
  const { handleSubmit, control, setValue, reset, clearErrors } = useForm();
  const navigate = useNavigate();
  const [loaded, setLoaded] = useState("");
  const [changeQR, setChangeQR] = useState(false);

  const convertToBase64 = (file) => {
    if (file) {
      clearErrors("qr");
      setLoaded(file.name);
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        let baseUrl = reader.result.split(",");
        setValue("qr", baseUrl[1]);
      };
    }
  };

  const handleQRChange = (evt) => {
    evt.preventDefault();
    setChangeQR(!changeQR);
  };

  const onSubmit = (data) => {
    barber === undefined ? create(data) : update({ ...data, id: Number(id) });
    navigate("/barbers/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    if (barber !== undefined) {
      console.log("barber", barber);
      setValue("name", barber.name);
      setValue("lastname", barber.lastname);
      setValue("document_number", barber.document_number);
      setValue("email", barber.email);
      setValue("alias", barber.alias);
      barber.qr && setValue("qr", barber.qr);
    }
  }, [setValue, barber]);

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">
          {barber === undefined ? "Nuevo barbero" : "Editar barbero"}
        </h3>
      </div>
      <Divider
        variant="middle"
        color={"#FFC107"}
        sx={{ width: "90%", margin: "5px 0" }}
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"name"}
                control={control}
                label={"Nombre"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"lastname"}
                control={control}
                label={"Apellido"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"document_number"}
                control={control}
                label={"DNI"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                  minLength: {
                    value: 7,
                    message: "Ingrese un número de documento válido",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"email"}
                control={control}
                label={"Email"}
                type={"email"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value:
                      /^(([^<>()[\]\\.,;:\s@”]+(\.[^<>()[\]\\.,;:\s@”]+)*)|(“.+”))@((\[[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}\.[0–9]{1,3}])|(([a-zA-Z\-0–9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "El correo ingresado no es válido",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name={"alias"}
                control={control}
                label={"Alias"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            {barber === undefined ? (
              <Grid item className="custom-form-grid-item">
                <UploadButton
                  name="qr"
                  control={control}
                  label={"Cargar código QR"}
                  rules={{
                    required: {
                      value: true,
                      message: "Debe subir un archivo",
                    },
                  }}
                  convert={(file) => convertToBase64(file)}
                  loaded={loaded}
                />
              </Grid>
            ) : (
              <Grid item className="custom-form-grid-item">
                {changeQR || barber.qr === "" || barber.qr === null ? (
                  <UploadButton
                    name="qr"
                    control={control}
                    label={"Cargar código QR"}
                    rules={{
                      required: {
                        value: true,
                        message: "Debe subir un archivo",
                      },
                    }}
                    convert={(file) => convertToBase64(file)}
                    loaded={loaded}
                  />
                ) : (
                  <>
                    <div className="custom-form-qr-display">
                      <h3 className="custom-form-qr-title">Código QR actual</h3>
                      <img
                        className="custom-form-qr-img"
                        src={`data:image/jpeg;base64,${barber.qr}`}
                        alt="custom-qr"
                      />
                      <CustomButton
                        onClick={(e) => handleQRChange(e)}
                        variant="text"
                        textColor={"black"}
                      >
                        Actualizar QR
                      </CustomButton>
                    </div>
                  </>
                )}
              </Grid>
            )}

            <Grid className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={resetForm}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {barber === undefined ? "Crear" : "Actualizar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default BarberForm;
