import React from "react";
import {
  Badge,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from "@mui/material";
import NotificationsActiveIcon from "@mui/icons-material/NotificationsActive";
import NotificationsIcon from "@mui/icons-material/Notifications";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import "./styles.scss";
import logo from "../../../assets/images/Artboard 1@1.5x.png";

const Home = () => {
  return (
    <div className="homeDashboard-main-container">
      <Stack
        className="home-dashboard-stack"
        direction={{
          xs: "column",
          sm: "column",
          md: "row",
        }}
      >
        <div className="home-dashboard-stack-welcome">
          <div className="home-image-container">
            <img src={logo} alt="logo" className="home-logo-image" />
          </div>
        </div>
        <div className="home-dashboard-stack-notifications">
          <div className="notifications-content">
            <Grid container spacing={2} className="notifications-grid">
              <Grid
                item
                xs={12}
                md={12}
                lg={12}
                className="notifications-grid-row-title"
              >
                <div className="notifications-title">
                  Notificaciones <NotificationsActiveIcon fontSize="large" />
                </div>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                className="notifications-column"
              >
                <List dense className="notifications-list">
                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>

                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>

                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>

                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>
                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>
                  <Badge
                    color="error"
                    badgeContent={1}
                    className="notification-circle"
                  >
                    <ListItem className="notification-body">
                      <ListItemIcon>
                        <NotificationsIcon fontSize="large" sx={{ mr: 2 }} />
                      </ListItemIcon>
                      <ListItemText
                        primary="Usted tiene una notificación"
                        secondary="Algún texto random"
                      />
                      <KeyboardDoubleArrowRightIcon />
                    </ListItem>
                  </Badge>
                </List>
              </Grid>
            </Grid>
          </div>
        </div>
      </Stack>
    </div>
  );
};

export default Home;
