import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import "../../../styles/FormStyles/styles.scss";
import React, { useState, useEffect } from "react";
import CustomTextField from "../../UI/CustomTextInput/index";
import { CustomButton } from "./../../../components/UI/CustomButton/index";
import { useForm } from "react-hook-form";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { useNavigate } from "react-router-dom";

const SellerForm = ({ id, sellerObject, create, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const onSubmit = (data) => {
    sellerObject === undefined
      ? create(data)
      : update({ ...data, id: Number(id) });
    navigate("/sellers/all");
  };

  const resetForm = () => {
    reset({});
  };

  useEffect(() => {
    if (sellerObject !== undefined) {
      setValue("name", sellerObject.name);
      setValue("lastname", sellerObject.lastname);
      setValue("email", sellerObject.email);
      setValue("password", sellerObject.password);
      setValue("document_number", sellerObject.document_number);
      setValue("username", sellerObject.username);
    }
  }, [setValue, sellerObject]);

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">
          {sellerObject === undefined ? "Nuevo vendedor" : "Editar vendedor"}
        </h3>
      </div>
      <Divider
        variant="middle"
        color="#FFC107"
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label="Nombre"
                value={sellerObject !== undefined ? sellerObject.name : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="lastname"
                control={control}
                label="Apellido"
                value={sellerObject !== undefined ? sellerObject.lastname : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="document_number"
                control={control}
                label="DNI"
                value={
                  sellerObject !== undefined ? sellerObject.document_number : ""
                }
                type={"text"}
                placeholder={"DNI sin puntos ni comas"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="email"
                control={control}
                label="Email"
                value={sellerObject !== undefined ? sellerObject.email : ""}
                type={"email"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /^\w+([.-_+]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/,
                    message: "El formato ingresado no es válido",
                  },
                }}
              />
            </Grid>
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label="Contraseña"
                value={sellerObject !== undefined ? sellerObject.password : ""}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOutlinedIcon />
                      ) : (
                        <VisibilityOffOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: false,
                    message: "Campo no obligatorio",
                  },
                }}
              />
            </Grid>
            {sellerObject !== undefined && (
              <Grid item className="custom-form-grid-item">
                <CustomTextField
                  name="username"
                  control={control}
                  label="Nombre de Usuario"
                  value={
                    sellerObject !== undefined ? sellerObject.username : ""
                  }
                  type={"text"}
                  rules={{
                    required: {
                      value: true,
                      message: "Campo obligatorio",
                    },
                  }}
                />
              </Grid>
            )}

            <Grid className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={resetForm}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {sellerObject === undefined ? "Crear" : "Actualizar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default SellerForm;
