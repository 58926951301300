import React from "react";
import "./styles.scss";
import {
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { Controller } from "react-hook-form";

const CustomSelect = ({ name, control, label, options, rules, value }) => {
  const generateOptions = () => {
    return options.map((option, index) => {
      return (
        <MenuItem key={index} value={option.value}>
          {option.title}
        </MenuItem>
      );
    });
  };

  return (
    <Box sx={{ minWidth: 60 }} className={"customSelect-main-container"}>
      <Controller
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <FormControl fullWidth className={"inner-container"}>
            <InputLabel
              color="neutral"
              id="franchisee-select-label"
              className={error ? "error-label" : "customSelect-label"}
              sx={{ fontWeight: 500 }}
            >
              {label}
            </InputLabel>
            <>
              <Select
                onChange={onChange}
                value={value}
                labelId="franchisee-select-label"
                variant="standard"
                error={error}
                renderValue={value.fullname && value.fullname}
              >
                {generateOptions()}
              </Select>
              <FormHelperText className={error ? "error-style" : ""}>
                {error ? error.message : null}
              </FormHelperText>
            </>
          </FormControl>
        )}
        control={control}
        name={name}
        defaultValue=""
        rules={rules}
      />
    </Box>
  );
};

export default CustomSelect;
