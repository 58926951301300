import React, { useEffect, useMemo, useState } from "react";
import "./styles.scss";
import { Dialog, Divider, Grid, Typography } from "@mui/material";
import { getValorizationHomeProvider } from "../../infrastructure/presentation/presenterProvider";
import BarbersList from "../../components/BarbersList";
import BarbersRating from "../../components/BarbersRating";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";

const searchCriteria = (text, searchText) => {
  return text.toLowerCase().indexOf(searchText.toLowerCase()) > -1;
};

const Valorization = () => {
  const [barbers, setBarbers] = useState([]);
  const [selected, setSelected] = useState({});
  const [searchText, setSearchText] = useState("");
  const [open, setOpen] = useState(false);
  const { isMobile } = useCheckMobileScreen();

  const viewHandlers = {
    getBarbersSuccess(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
      setBarbers(data.data.data);
    },
    getBarbersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getValorizationHomeProvider(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllBarbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const optionsToShow = useMemo(
    () =>
      barbers &&
      barbers.filter((option) =>
        searchCriteria(
          `${option.id} ${option.first_name} ${option.last_name} ${option.document_number}`,
          searchText
        )
      ),
    [barbers, searchText]
  );

  const changeCriteria = (e) => {
    setSearchText(e.target.value);
  };

  const handleBarber = (data) => {
    setSelected(data);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="valorization-screen">
      <Grid container className="valorization-main-grid">
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="valorization-grid-item"
        >
          <Typography
            variant="h4"
            className="valorization-grid-item-title"
            gutterBottom
          >
            Lista de Barberos
          </Typography>
          <Divider
            sx={{
              mb: 2,
              width: "90%",
            }}
            variant="middle"
            color={"#ffc107"}
          />
          <BarbersList
            barbers={optionsToShow}
            handleSearch={changeCriteria}
            handleSelect={handleBarber}
            open={open}
            setOpen={setOpen}
          />
        </Grid>

        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          className="valorization-grid-item"
        >
          {JSON.stringify(selected) !== "{}" &&
            (isMobile ? (
              <Dialog onClose={handleClose} open={open} className=''>
                <Typography
                  variant="h4"
                  className="valorization-grid-item-title"
                  gutterBottom
                >
                  Detalles del barbero
                </Typography>
                <Divider
                  sx={{
                    mb: 2,
                    width: "90%",
                  }}
                  variant="middle"
                  color={"#ffc107"}
                />
                <BarbersRating barber={selected} />
              </Dialog>
            ) : (
              <>
                <Typography
                  variant="h4"
                  className="valorization-grid-item-title"
                  gutterBottom
                >
                  Detalles del barbero
                </Typography>
                <Divider
                  sx={{
                    mb: 2,
                    width: "90%",
                  }}
                  variant="middle"
                  color={"#ffc107"}
                />
                <BarbersRating barber={selected} />
              </>
            ))}
        </Grid>
      </Grid>
    </div>
  );
};

export default Valorization;
