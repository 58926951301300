export const BillsDashboardPresenter = (getAllOwnersGateway, viewHandlers) => {
  return {
    present() {
      console.log("Bills dashboard was rendered!");
    },
    getAllOwners() {
      const getOwnersPromise = getAllOwnersGateway.getAllOwners();

      getOwnersPromise
        .then((data) => {
          viewHandlers.getAllOwnersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllOwnersError(err);
        });
    },
  };
};
