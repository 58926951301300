export const RatesDetailPresenter = (getRateGateway, viewHandlers) => {
  return {
    present() {
      console.log("Rates detail was rendered!");
    },
    getRateById(id) {
      const getRatePromise = getRateGateway.getRateById(id);

      getRatePromise
        .then((data) => {
          viewHandlers.getRateSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getRateError(err);
        });
    },
  };
};
