import axios from "axios";

export const HttpUpdateOwnerGateway = () => {
  return {
    updateOwner: async (data) => {
      const {
        id,
        fullname,
        dni,
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission,
        phone,
        contract_date,
      } = data;
      const body = {
        id: Number(id),
        fullname,
        dni: Number(dni),
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission: Number(commission),
        phone,
        contract_date,
      };
      return axios.put(`${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/owner/update`, body);
    },
  };
};
