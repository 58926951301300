import React from "react";
import "./styles.scss";
import { Divider, Grid, Typography } from "@mui/material";
import DataCard from "../../components/DataCard";
import BarChart from "../../../../components/UI/BarChart/BarChart";

const SalesHome = () => {
  const today = new Date().toLocaleDateString();

  return (
    <div className="sales-home-screen">
      <div className="sales-home-title-container">
        <Typography variant="h4" gutterBottom className="sales-home-title">
          Ventas
        </Typography>
      </div>
      <div className="sales-home-main-container">
        <Grid container spacing={2} className="sales-home-grid">
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="sales-home-grid-item"
          >
            <Typography
              variant="overline"
              gutterBottom
              className="grid-item-title"
            >
              Ventas del día
            </Typography>
            <DataCard name={"Lorem Ipsum"} date={today}>
              <Grid container spacing={2} className="sales-data-card-grid">
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ventas: </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ingresos: $</div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Efectivo: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Digital: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            className="sales-home-grid-item"
          >
            <Typography
              variant="overline"
              gutterBottom
              className="grid-item-title"
            >
              Ventas de la semana
            </Typography>
            <div className="grid-item-chart-container">
              <BarChart
                /* title={"Ventas"} */
                labels={[
                  "Lunes",
                  "Martes",
                  "Miércoles",
                  "Jueves",
                  "Viernes",
                  "Sábado",
                  "Domingo",
                ]}
                scores={[1, 2, 3, 4, 5, 6, 7]}
                tooltipLabel={"ventas"}
                legend={false}
              />
            </div>
          </Grid>
        </Grid>
      </div>
      <Divider variant="middle" className="separator" color="#ffc107" />
      <div className="sales-home-months-container">
        <Grid container spacing={2} className="sales-home-months-grid">
          <Grid item xs={12} sm={12} md={6} lg={6} className="months-grid-item">
            <Typography
              variant="overline"
              gutterBottom
              className="grid-item-title"
            >
              Ventas mes actual
            </Typography>
            <DataCard name={"Lorem Ipsum"} date={today}>
              <Grid container spacing={2} className="sales-data-card-grid">
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ventas: </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ingresos: $</div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Efectivo: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Digital: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} className="months-grid-item">
            <Typography
              variant="overline"
              gutterBottom
              className="grid-item-title"
            >
              Ventas mes anterior
            </Typography>
            <DataCard name={"Lorem Ipsum"} date={today}>
              <Grid container spacing={2} className="sales-data-card-grid">
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ventas: </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Ingresos: $</div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Efectivo: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
                <Grid
                  item
                  className="sales-data-card-grid-item"
                  xs={12}
                  md={12}
                  lg={12}
                >
                  <div className="grid-item-data-title">✓ Digital: $ </div>
                  <div className="grid-item-value">value </div>
                </Grid>
              </Grid>
            </DataCard>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default SalesHome;
