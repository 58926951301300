import React, { useEffect } from "react";
import "./styles.scss";
import { getCustomerCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import CustomerForm from "../../../../components/Forms/CustomersForm";

const CreateCustomer = () => {
  const viewHandlers = {
    createClientSuccess(data) {
      console.log("<<<<<Create client>>>>>", data.statusText);
    },
    createClientError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getCustomerCreatePresenter(viewHandlers);

  const createClient = (data) => {
    presenter.createClient(data);
  };

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-customer-screen">
      <div className="create-customer-form-container">
        <CustomerForm create={createClient} />
      </div>
    </div>
  );
};

export default CreateCustomer;
