export const DailySummaryPresenter = (viewHandlers, getCutsByDayGateway) => {
  return {
    present() {
      console.log("Daily summary was rendered!");
    },
    getCutsByDay(id) {
      const getCutsByDayPromise = getCutsByDayGateway.getCutsByDay(id);

      getCutsByDayPromise
        .then((data) => {
          viewHandlers.getCutsByDaySuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCutsByDayError(err);
        });
    },
  };
};
