import React from "react";
import "./styles.scss";
import { Avatar, Card, CardContent, CardHeader, Divider } from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";

const DataCard = ({ name, date, children }) => {
  return (
    <Card className="sales-data-card" elevation={5} sx={{ maxWidth: 545 }}>
      <CardHeader
        avatar={
          <Avatar>
            <PersonIcon fontSize="medium" />
          </Avatar>
        }
        className="sales-data-card-header"
        title={`${name}`}
        subheader={`${date}`}
      />
      <Divider
        variant="middle"
        className="sales-data-card-separator"
        color="#ffc107"
      />
      <CardContent className="sales-data-card-body">{children}</CardContent>
    </Card>
  );
};

export default DataCard;
