import axios from "axios";

export const HttpGetCutsByDay = () => {
  return {
    getCutsByDay: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/cut/get-cuts-by-day/${id}`
      );
    },
  };
};
