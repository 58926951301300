export const BarberAddBarberPresenter = (
  getAllBarberShopsGateway,
  getBarberGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Barbershop add barber was rendered!");
    },
    getAllBarberShops() {
      const getBarbershopPromise = getAllBarberShopsGateway.getAllBarberShops();

      getBarbershopPromise
        .then((data) => {
          viewHandlers.getAllBarberShopsSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllBarberShopsError(err);
        });
    },
    getBarber(id) {
      const getAllBarbersPromise = getBarberGateway.getBarber(id);

      getAllBarbersPromise
        .then((data) => {
          viewHandlers.getBarberSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarberError(err);
        });
    },
  };
};
