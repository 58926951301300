import { HttpGetAllBranchOffices } from "../gateways/HttpGetAllBranchOfficesGateway";
import { HttpGetCutsByDay } from "../gateways/HttpGetCutsByDayGateway";
import { HttpGetCutsByMonth } from "../gateways/HttpGetCutsByMonthGateway";
import { HttpGetCustomersByGenderGateway } from "../gateways/HttpGetCustomersByGenderGateway";

import { MetricsHomePresenter } from "./MetricsHomePresenter";
import { DailySummaryPresenter } from "./DailySummaryPresenter";
import { MonthlySummaryPresenter } from "./MonthlySummaryPresenter";
import { GlobalSummaryPresenter } from "./GlobalSummaryPresenter";

export const getMetricsHomePresenter = (viewHandlers) => {
  const getAllBranchOfficesGateway = HttpGetAllBranchOffices();
  return MetricsHomePresenter(getAllBranchOfficesGateway, viewHandlers);
};

export const getDailySummaryPresenter = (viewHandlers) => {
  const getCutsByDayGateway = HttpGetCutsByDay();
  return DailySummaryPresenter(viewHandlers, getCutsByDayGateway);
};

export const getMonthlySummaryPresenter = (viewHandlers) => {
  const getCutsByMontGateway = HttpGetCutsByMonth();
  return MonthlySummaryPresenter(viewHandlers, getCutsByMontGateway);
};

export const getGlobalSummaryPresenter = (viewHandlers) => {
  const getCustomersByGenderGateway = HttpGetCustomersByGenderGateway();
  return GlobalSummaryPresenter(getCustomersByGenderGateway, viewHandlers);
};
