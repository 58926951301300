import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import CustomModal from "../UI/CustomModal/Index";
import useUser from "../../hooks/useUser";
import { Roles } from "../../constants/roles";

function ActionButtons({ data, category, title, disabledOption }) {
  const { user } = useUser();

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const getRowId = () => {
    const rowData = {};
    data.api
      .getAllColumns()
      .filter((col) => col.field !== "__check__" && !!col)
      .forEach(
        (col) => (rowData[col.field] = data.getValue(data.id, col.field))
      );
    return rowData.id;
  };

  const toEdit = (e) => {
    e.stopPropagation();
    const id = getRowId();
    navigate(`/${category}/edit/${id}`);
  };

  const toDelete = (e) => {
    e.stopPropagation();
    setOpen(!open);
  };

  const toDetail = (e) => {
    e.stopPropagation();
    const id = getRowId();
    navigate(`/${category}/detail/${id}`);
  };

  return (
    <Stack direction="row" spacing={1}>
      <Tooltip title="Detalles" placement="top">
        <IconButton onClick={toDetail}>
          <InfoIcon />
        </IconButton>
      </Tooltip>
      <Tooltip title="Editar" placement="top">
        <IconButton onClick={toEdit}>
          <EditIcon />
        </IconButton>
      </Tooltip>
      {user.role !== Roles.Seller && (
        <Tooltip title="Eliminar" placement="top">
          <IconButton onClick={toDelete} disabled={disabledOption === 1}>
            <DeleteIcon />
            {open && (
              <CustomModal
                id={getRowId()}
                open={open}
                setOpen={setOpen}
                category={category}
                title={title}
              ></CustomModal>
            )}
          </IconButton>
        </Tooltip>
      )}
    </Stack>
  );
}

export default ActionButtons;
