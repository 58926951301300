import React from "react";
import "./styles.scss";
import {
  Box,
  InputAdornment,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonIcon from "@mui/icons-material/Person";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { List } from "reactstrap";
import useCheckMobileScreen from "../../../../hooks/useCheckMobileScreen";

const BarbersList = ({ barbers, handleSearch, handleSelect, setOpen }) => {
  const { isMobile } = useCheckMobileScreen();

  const handleSelectChange = (barber) => {
    if (isMobile) {
      setOpen(true);
      handleSelect(barber);
    } else {
      handleSelect(barber);
    }
  };

  return (
    <Box className="barbers-list-main-container">
      <ListSubheader className="barbers-list-search-bar">
        <TextField
          className="barbers-search-bar-input"
          size="small"
          autoFocus
          placeholder="Escriba para buscar..."
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
          onChange={(e) => handleSearch(e)}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
      </ListSubheader>
      <List dense className="barbers-list">
        {barbers.map((barber) => (
          <ListItem
            className="barbers-list-item"
            key={barber.id}
            onClick={() => handleSelectChange(barber)}
          >
            <ListItemIcon>
              <PersonIcon fontSize="large" sx={{ mr: 2 }} />
            </ListItemIcon>
            <ListItemText
              className="list-item-data"
              primary={`${barber.name} ${barber.lastname} |  Username: ${barber.username}`}
            />
            <KeyboardDoubleArrowRightIcon />
          </ListItem>
        ))}
      </List>
    </Box>
  );
};

export default BarbersList;
