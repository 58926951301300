import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { getSellersEditPresenter } from "../../infrastructure/presentation/presenterProvider";
import SellerForm from "../../../../components/Forms/SellerForm";

export const SellersEdit = () => {
  const { id } = useParams();
  const [seller, setSeller] = useState({});

  const viewHandlers = {
    getSellerSucces(data) {
      setSeller(data.data.data);
    },
    getSellerError(err) {
      console.log("ERROR: ", err);
    },
    updateSellerSucces(data) {
      console.log("Updated Seller: ", data);
    },
    updateSellerError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getSellersEditPresenter(viewHandlers);

  const editSeller = (data) => {
    presenter.updateSeller(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getSeller(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="update-seller-screen">
      <div className="update-seller-form-container">
        <SellerForm id={id} update={editSeller} sellerObject={seller} />
      </div>
    </div>
  );
};

export default SellersEdit;
