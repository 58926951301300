import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Loader from "../../../../components/UI/Loader";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { getBarberShopDeletePresenter } from "../../infrastructure/presentation/presenterProvider";

export const BarberShopsDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const viewHandlers = {
    deleteBarberShopSuccess(data) {
      console.log("<<<<<<DELETE BARBERSHOP>>>>>>>", data.statusText);
    },
    deleteBarberShopError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberShopDeletePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.deleteBarberShop(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [id]);

  const handleClose = () => {
    setOpen(false);
    navigate("/barbershops/all");
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div className="loading-main-container">
            <DialogContent className="loading-screen-body">
              <div className="loading-img-container">
                <img
                  width={275}
                  src={require("../../../../assets/images/Artboard 1@1.5x.png")}
                  alt="logo"
                />
              </div>
              <Divider variant="middle" color="#FFC107" />
              <div className="loader-container">
                <Loader />
              </div>
            </DialogContent>
          </div>
        ) : (
          <div className="confirm-screen">
            <div className="title-container">
              <DialogTitle>Se ha eliminado el registro con éxito</DialogTitle>
              <Divider variant="middle" color="#FFC107" />
              <DialogContent className="confirm-button-container">
                <DialogActions>
                  <CustomButton
                    onClick={handleClose}
                    variant={"text"}
                    textColor={"black"}
                  >
                    Aceptar
                  </CustomButton>
                </DialogActions>
              </DialogContent>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default BarberShopsDelete;
