import React from "react";
import "./styles.scss";
import { getBarberAddBarberPresenter } from "../../infrastructure/presentation/presenterProvider";
import { useEffect } from "react";
import { Divider, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { useState } from "react";
import BarberShopsSelector from "../../components/BarberShopsSelector/index";
import CommissionSlider from "../../components/CommissionSlider/index";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { DataGrid } from "@mui/x-data-grid";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    width: 40,
  },
  {
    field: "name",
    headerName: "Barbería",
    flex: 1,
    minWidth: 100,
    headerClassName: "barbers-table-header",
    headerAlign: "center",
  },
  {
    field: "status",
    headerName: "Comisión",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
  },
];

export const BarberAddBarberShops = () => {
  const { id } = useParams();
  const [barberShops, setBarberShops] = useState([]);
  const [barber, setBarber] = useState(null);
  const [selectedBarberShop, setSelectedBarberShop] = useState({});
  const [commission, setCommission] = useState(0);
  const [error, setError] = useState(false);
  const [sliderError, setSliderError] = useState(false);

  const viewHandlers = {
    getAllBarberShopsSuccess(data) {
      console.log("<<< BARBERSHOPS SUCCESS >>>", data.statusText);
      setBarberShops(data.data.data);
    },
    getAllBarberShopsError(err) {
      console.log("<<< BARBERSHOPS ERROR >>>", err);
    },
    getBarberSuccess(data) {
      console.log("<<< BARBER SUCCESS >>>", data.statusText);
      setBarber(data.data.data);
    },
    getBarberError(err) {
      console.log("<<< BARBER ERROR >>>", err);
    },
  };

  const presenter = getBarberAddBarberPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
    presenter.getBarber(id);
  }, [id, presenter]);

  const addBarberShopToBarber = () => {
    if (JSON.stringify(selectedBarberShop) === "{}") {
      setError(true);
    } else {
      console.log("ID BARBERSHOP: ", selectedBarberShop.id);
      console.log("ID BARBER: ", barber.id);
      console.log("COMMISSION: ", commission);
    }
  };

  return (
    <div className="barber-add-main-container">
      <div className="barber-add-title-container">
        <Typography variant="h4" gutterBottom component="div">
          {barber && barber.name} {barber && barber.lastname}
        </Typography>
      </div>
      <div className="barber-add-section-container">
        <h3 className="barber-add-section-title">
          {`Seleccione la sucursal con la que desea que ${
            barber && barber.name
          } sea asociado.`}
        </h3>
        <div className="barber-add-content-container">
          <BarberShopsSelector
            barberShops={barberShops}
            error={error}
            setError={setError}
            setSelectedBarberShop={setSelectedBarberShop}
          />
        </div>
      </div>
      <div className="barber-add-section-container">
        <h3 className="barber-add-section-title">
          {`Para poder vincular a ${
            JSON.stringify(selectedBarberShop) !== "{}"
              ? selectedBarberShop.name
              : "una barbería"
          } al barbero ${
            barber && barber.name
          } debe indicar el porcentaje de comisión por el cual va a trabajar`}
        </h3>
        <div className="barber-add-content-container">
          <CommissionSlider
            commission={commission}
            setCommission={setCommission}
            error={sliderError}
            setError={setSliderError}
          />
        </div>
      </div>
      <div className="barber-add-section-container">
        <div className="barber-add-button-container">
          <CustomButton
            onClick={() => addBarberShopToBarber()}
            variant="outlined"
            icon={<AddBusinessIcon />}
            color="#FFC107"
            textColor={"black"}
          >
            Añadir
          </CustomButton>
        </div>
      </div>
      <Divider
        sx={{ mt: 5, mb: 5, width: "100%" }}
        variant="middle"
        color={"#ffc107"}
      />
      <div className="barbers-table-container">
        <div className="barbers-table-title-container">
          <Typography variant="h5" gutterBottom component="div">
            Listado de barberías en las que
            {` ${barber && barber.name}`} {` ${barber && barber.lastname}`}{" "}
            actualmente trabaja
          </Typography>
        </div>
        <div className="barbers-table-inner-container" style={{ height: 350 }}>
          <DataGrid
            className="barbers-table"
            disableColumnFilter
            disableColumnSelector
            columns={columns}
            rows={barberShops && barberShops}
          />
        </div>
      </div>
    </div>
  );
};

export default BarberAddBarberShops;
