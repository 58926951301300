import axios from "axios";

export const HttpGetRateGateway = () => {
  return {
    getRateById: async (id) => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/rate/get-specific-rate/id/${id}`
      );
    },
  };
};
