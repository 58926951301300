import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import BarberForm from "../../../../components/Forms/BarberForm";
import { getBarbersEditPresenter } from "./../../infrastructure/presentation/presenterProvider";

export const EditBarber = () => {
  const { id } = useParams();
  const [barber, setBarber] = useState({});

  const viewHandlers = {
    getBarberByIdSucces(data) {
      setBarber(data.data.data);
    },
    getBarberByIdError(err) {
      console.log("ERROR: ", err);
    },
    updateBarberSuccess(data) {
      console.log("Updated Barber: ", data.statusText);
    },
    updateBarberError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getBarbersEditPresenter(viewHandlers);

  const editBarber = (data) => {
    presenter.updateBarber(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getBarberById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edit-barber-screen">
      <div className="edit-barber-form-container">
        <BarberForm id={id} update={editBarber} barber={barber} />
      </div>
    </div>
  );
};

export default EditBarber;
