import axios from "axios";

export const HttpDeleteBarberShop = () => {
  return {
    deleteBarberShop: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/branch-office/delete/id/${id}`
      );
    },
  };
};
