import ReceiptLongIcon from "@mui/icons-material/ReceiptLong";
import GroupIcon from "@mui/icons-material/Group";
import StoreIcon from "@mui/icons-material/Store";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ContentCutIcon from "@mui/icons-material/ContentCut";
import PersonIcon from "@mui/icons-material/Person";
import PieChartIcon from "@mui/icons-material/PieChart";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import StarIcon from "@mui/icons-material/Star";
import CommentIcon from "@mui/icons-material/Comment";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import BarChartIcon from "@mui/icons-material/BarChart";
import PointOfSaleIcon from "@mui/icons-material/PointOfSale";

/* Cambiar el rol de la AdminNavbar por 'admin' una vez que ya esté todo listo */
const AdminNavbar = {
  role: "admin",
  routes: [
    {
      id: 1,
      title: "Barberías",
      icon: <StoreIcon />,
      url: "/barbershops/all",
    },
    {
      id: 2,
      title: "Facturación",
      icon: <ReceiptLongIcon />,
      url: "/billing",
    },
    {
      id: 3,
      title: "Dueños",
      icon: <PersonIcon />,
      url: "/owners/all",
    },
    {
      id: 4,
      title: "Tarifas",
      icon: <AttachMoneyIcon />,
      url: "/rates/all",
    },
    {
      id: 5,
      title: "Barberos",
      icon: <ContentCutIcon />,
      url: "/barbers/all",
    },
    {
      id: 6,
      title: "Vendedores",
      icon: <GroupIcon />,
      url: "/sellers/all",
    },
    {
      id: 7,
      title: "Agregar Cliente",
      icon: <PersonAddAlt1Icon />,
      url: "/clients/register",
    },
    {
      id: 8,
      title: "Metricas",
      icon: <PieChartIcon />,
      url: "/metrics",
    },
  ],
};

const SellersNavbar = {
  role: "seller",
  routes: [
    {
      id: 1,
      title: "Ventas",
      icon: <PointOfSaleIcon />,
      url: "/sales",
    },
    {
      id: 2,
      title: "Dueños",
      icon: <PersonIcon />,
      url: "/owners/all",
    },
    {
      id: 3,
      title: "Barberías",
      icon: <StoreIcon />,
      url: "/barbershops/all",
    },
    {
      id: 4,
      title: "Tarifas",
      icon: <AttachMoneyIcon />,
      url: "/rates/all",
    },
    {
      id: 5,
      title: "Barberos",
      icon: <ContentCutIcon />,
      url: "/barbers/all",
    },
    {
      id: 6,
      title: "Agregar Cliente",
      icon: <PersonAddAlt1Icon />,
      url: "/clients/register",
    },
  ],
};

const OwnersNavbar = {
  role: "owner",
  routes: [
    {
      id: 1,
      title: "Metricas",
      icon: <PieChartIcon />,
      url: "/metrics",
    },
    {
      id: 2,
      title: "Barberías",
      icon: <StoreIcon />,
      url: "/barbershops/all",
    },
    {
      id: 3,
      title: "Tarifas",
      icon: <AttachMoneyIcon />,
      url: "/rates/all",
    },
    {
      id: 5,
      title: "Barberos",
      icon: <ContentCutIcon />,
      url: "/barbers/all",
    },
    //TODO ELIMINAR ESTE MENÚ
    {
      id: 6,
      title: "Vendedores",
      icon: <GroupIcon />,
      url: "/sellers/all",
    },
    {
      id: 7,
      title: "Agregar Cliente",
      icon: <PersonAddAlt1Icon />,
      url: "/clients/register",
    },
  ],
};

const BarbersNavbar = {
  role: "barber",
  routes: [
    {
      id: 1,
      title: "Rendimiento",
      icon: <BarChartIcon />,
      url: "/performance",
    },
    {
      id: 2,
      title: "Agregar Cliente",
      icon: <PersonAddAlt1Icon />,
      url: "/clients/register",
    },
    {
      id: 3,
      title: "Cargar QR",
      icon: <QrCode2Icon />,
      url: "/modify-qr",
    },
  ],
};

const ClientNavbar = {
  role: "client",
  routes: [
    {
      id: 1,
      title: "Ranking de barberos",
      icon: <LeaderboardIcon />,
      url: "/ranking",
    },
    {
      id: 2,
      title: "Valorización",
      icon: <StarIcon />,
      url: "/valorize",
    },
    {
      id: 3,
      title: "Comentar",
      icon: <CommentIcon />,
      url: "/comment",
    },
    {
      id: 4,
      title: "Agregar foto",
      icon: <AddAPhotoIcon />,
      url: "/add-photo",
    },
  ],
};

export const Navs = [
  AdminNavbar,
  SellersNavbar,
  OwnersNavbar,
  BarbersNavbar,
  ClientNavbar,
];
