import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
} from "@mui/material";
import Loader from "../../../../components/UI/Loader";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { getRatesDeletePresenter } from "../../infrastructure/presentation/presenterProvider";

export const RatesDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);

  const viewHandlers = {
    deleteRateSuccess(data) {
      console.log("<<<<<<DELETE RATE>>>>>>>", data.data.message);
    },
    deleteRateError(err) {
      console.log("<<<<<<DELETE RATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesDeletePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.deleteRate(id);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setLoading(true);
    setOpen(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [id]);

  const closeDialog = () => {
    setOpen(false);
    navigate("/rates/all");
  };

  return (
    <div>
      <Dialog
        open={open}
        aria-labelledby="rates-delete-dialog"
        aria-describedby="rates-delete-description"
      >
        {loading ? (
          <div className="delete-loading-main-container">
            <DialogContent className="loading-delete-screen">
              <div className="delete-img-container">
                <img
                  width={275}
                  src={require("../../../../assets/images/Artboard 1@1.5x.png")}
                  alt="logo"
                />
              </div>
              <Divider variant="middle" color="#FFC107" />
              <div className="delete-loader-container">
                <Loader />
              </div>
            </DialogContent>
          </div>
        ) : (
          <div className="confirm-delete-screen">
            <div className="confirm-title-container">
              <DialogTitle>Se ha eliminado el registro con éxito</DialogTitle>
              <Divider variant="middle" color="#FFC107" />
              <DialogContent className="delete-button-container">
                <DialogActions>
                  <CustomButton
                    onClick={closeDialog}
                    variant={"text"}
                    textColor={"black"}
                  >
                    Aceptar
                  </CustomButton>
                </DialogActions>
              </DialogContent>
            </div>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default RatesDelete;
