import React, { useEffect, useState } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import OwnerForm from "../../../../components/Forms/OwnerForm";
import { getOwnersEditPresenter } from "../../infrastructure/presentation/presenterProvider";

export const OwnersEdit = () => {
  const { id } = useParams();
  const [owner, setOwner] = useState({});

  const viewHandlers = {
    getOwnerSucces(data) {
      setOwner(data.data.data);
    },
    getOwnerError(err) {
      console.log("ERROR: ", err);
    },
    updateOwnerSucces(data) {
      console.log("Updated Owner: ", data);
    },
    updateOwnerError(err) {
      console.log("ERROR: ", err);
    },
  };

  const presenter = getOwnersEditPresenter(viewHandlers);

  const editOwner = (data) => {
    presenter.updateOwner(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getOwner(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edit-owners-screen">
      <div className="edit-owners-form-container">
        <OwnerForm id={id} update={editOwner} ownerObject={owner} />
      </div>
    </div>
  );
};

export default OwnersEdit;
