import { useEffect, useState } from "react";

const useCheckMobileScreen = () => {
  const [isTouchDevice, setIsTouchDevice] = useState(false);

  const handleWindowSizeChange = () => {
    if (!window.matchMedia) {
      return;
    } else {
      setIsTouchDevice(window.matchMedia("(pointer:coarse)").matches);
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return {
    isMobile: isTouchDevice,
  };
};

export default useCheckMobileScreen;
