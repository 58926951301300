import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  ListItemAvatar,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
/* import { GetBillsMocks } from "../../services/bills.services"; */
import BillsForm from "../../components/BillsForm";
import { getBillsDashboardPresenter } from "../../infrastructure/presentation/presenterProvider";

const deudores = [
  {
    address: "Calle 123",
    comment: "Abc",
    commission: 25,
    contract_date: "2023-06-01T20:49:28.000Z",
    country: "Argentina ",
    created_at: "2023-06-01T20:49:29.000Z",
    dni: 43354041,
    email: "juan@juan.com",
    fullname: "Juan Elias ",
    id: 1,
    locality: "Lujan",
    phone: "2615673118",
    province: "Mendoza",
    role: "owner",
    status: 1,
    updated_at: "2023-06-01T20:49:29.000Z",
  },
  {
    address: "Calle 123",
    comment: "Abc",
    commission: 25,
    contract_date: "2023-06-01T20:49:28.000Z",
    country: "Argentina ",
    created_at: "2023-06-01T20:49:29.000Z",
    dni: 43354041,
    email: "juan@juan.com",
    fullname: "Juan Elias ",
    id: 1,
    locality: "Lujan",
    phone: "2615673118",
    province: "Mendoza",
    role: "owner",
    status: 1,
    updated_at: "2023-06-01T20:49:29.000Z",
  },
  {
    address: "Calle 123",
    comment: "Abc",
    commission: 25,
    contract_date: "2023-06-01T20:49:28.000Z",
    country: "Argentina ",
    created_at: "2023-06-01T20:49:29.000Z",
    dni: 43354041,
    email: "juan@juan.com",
    fullname: "Juan Elias ",
    id: 1,
    locality: "Lujan",
    phone: "2615673118",
    province: "Mendoza",
    role: "owner",
    status: 1,
    updated_at: "2023-06-01T20:49:29.000Z",
  },
];

export const BillsDashboard = () => {
  // eslint-disable-next-line no-unused-vars
  const [bills, setBills] = useState([]);
  const [owners, setOwners] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [owner, setOwner] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [fromDate, setFromDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [toDate, setToDate] = useState("");

  const viewHandlers = {
    getAllOwnersSuccess(data) {
      console.log("<<<<<<OWNERS>>>>>>>", data.statusText);
      let ownersList = data.data.data;
      setOwners(
        ownersList.map((item) => ({
          value: item,
          title: item.fullname,
        }))
      );
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBillsDashboardPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bills-dashboard-main-container">
      <div className="bills-dashboard-screen-title-container">
        <div className="bills-dashboard-screen-title"> Facturación </div>
      </div>
      <div className="bills-dashboard-form-container">
        <BillsForm
          owners={owners}
          setFromDate={setFromDate}
          setToDate={setToDate}
          setOwner={setOwner}
        />
      </div>
      <Grid container spacing={2} className="bills-dashboard-grid-container">
        <Grid item xs={12} md={12} className="bills-dashboard-grid-item">
          <Card className="bills-dashboard-data-container" elevation={3}>
            <CardContent>
              <Typography variant="h5" component="div">
                Lista de deudores
              </Typography>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} md={12} className="bills-dashboard-grid-item">
          <Grid container spacing={3} className="debtors-grid-container">
            {deudores.map((deudor, index) => {
              return (
                <Grid
                  item
                  xs={12}
                  md={4}
                  lg={4}
                  key={index}
                  className="debtor-item-container"
                >
                  <Card className="debtor-item" elevation={4}>
                    <div className="debtor-item-avatar-container">
                      <ListItemAvatar className="debtor-item-avatar">
                        <Avatar>
                          <PersonIcon fontSize="large" />
                        </Avatar>
                      </ListItemAvatar>
                    </div>
                    <div className="debtor-item-data-container">
                      <Typography
                        variant="overline"
                        gutterBottom
                        className="debtor-item-name"
                      >
                        {deudor.fullname}
                      </Typography>

                      <Typography
                        variant="body2"
                        gutterBottom
                        className="debtor-item-data"
                      >
                        Algún detalle de la deuda
                      </Typography>
                    </div>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};

export default BillsDashboard;
