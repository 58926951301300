import React, { useEffect, useState } from "react";
import "./styles.scss";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { Divider, Grid } from "@mui/material";
import BarChart from "../../../../components/UI/BarChart/BarChart";
import { getDailySummaryPresenter } from "../../infrastructure/presentation/presenterProvider.js";

const translatedDays = {
  Mon: "Lunes",
  Tue: "Martes",
  Wed: "Miércoles",
  Thu: "Jueves",
  Fri: "Viernes",
  Sat: "Sábado",
  Sun: "Domingo",
};

const DailySummary = ({ branchOffice }) => {
  const [cuts, setCuts] = useState([]);
  const [barLabels, setBarLabels] = useState([]);
  const [barValues, setBarValues] = useState([]);
  const [total, setTotal] = useState(0);
  dayjs.extend(isBetween);

  const viewHandlers = {
    getCutsByDaySuccess(data) {
      console.log("<<<<<<CUTS>>>>>>>", data.statusText);
      setCuts(data.data.data);
    },
    getCutsByDayError(err) {
      console.log("<<<<<<CUTS ERROR>>>>>>>", err);
    },
  };

  const presenter = getDailySummaryPresenter(viewHandlers);

  const isInRange = (date) => {
    const now = dayjs();
    const cutDate = dayjs(date);
    const auxDate = new Date();
    new Date(auxDate.setDate(auxDate.getDate() - 7));
    const aWeekAgo = dayjs(auxDate);
    return cutDate.isBetween(aWeekAgo, now, null, "[]");
  };

  useEffect(() => {
    presenter.present();
    branchOffice
      ? presenter.getCutsByDay(branchOffice.id)
      : presenter.getCutsByDay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [branchOffice]);

  useEffect(() => {
    let auxLabels = [];
    let auxCuts = [];
    let auxTotal = 0;
    // eslint-disable-next-line array-callback-return
    cuts.map((cut) => {
      if (isInRange(cut.date)) {
        auxLabels.push(translatedDays[cut.day_name]);
        auxCuts.push(cut.total_cuts);
        auxTotal += Number(cut.total_cuts);
      }
    });
    setBarLabels(auxLabels);
    setBarValues(auxCuts);
    setTotal(auxTotal);
  }, [cuts]);

  return (
    <div className="daily-summary-screen">
      <Grid container className="daily-summary-grid">
        <Grid item xs={12} sm={12} className="daily-summary-grid-item">
          <BarChart
            title={"Cortes por día"}
            labels={barLabels && barLabels}
            scores={barValues && barValues}
            tooltipLabel={"Cortes"}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="daily-summary-grid-item"
        >
          <div className="daily-summary-data-container">
            <h6 className="daily-summary-data-title">
              Total de cortes en la semana
            </h6>
            <Divider variant="middle" color={"#FFC107"} sx={{ width: "90%" }} />
            <p className="daily-summary-data">
              {total ? `${total} cortes` : "0"}
            </p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="daily-summary-grid-item"
        >
          <div className="daily-summary-data-container">
            <h6 className="daily-summary-data-title">Pagos en efectivo</h6>
            <Divider variant="middle" color={"#FFC107"} sx={{ width: "90%" }} />
            <p className="daily-summary-data">falta data</p>
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={4}
          lg={4}
          className="daily-summary-grid-item"
        >
          <div className="daily-summary-data-container">
            <h6 className="daily-summary-data-title">Pagos en digital</h6>
            <Divider variant="middle" color={"#FFC107"} sx={{ width: "90%" }} />
            <p className="daily-summary-data">falta data</p>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default DailySummary;
