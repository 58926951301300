import axios from "axios";

export const HttpDeleteRateGateway = () => {
  return {
    deleteRate: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/rate/delete/id/${id}`
      );
    },
  };
};
