import axios from "axios";

export const HttpRegisterGateway = () => {
  return {
    registerBarber: async (data) => {
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/register/barber`,
        data
      );
    },
    registerOwner: async (data) => {
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/register/owner`,
        data
      );
    },
    registerSeller: async (data) => {
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/register/seller`,
        data
      );
    },
  };
};
