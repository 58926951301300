import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { getBarberShopDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import Loader from "../../../../components/UI/Loader";
import logo from "../../../../assets/images/Artboard 1@1.5x.png";

export const BarberShopsDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [barberShop, setBarberShop] = useState({});
  const [owner, setOwner] = useState({});
  const [address, setAddress] = useState({});

  const viewHandlers = {
    getBarberShopSuccess(data) {
      console.log("<<<<<<DATA DETAIL>>>>>>>", data.statusText);
      setTimeout(() => {
        setBarberShop(data.data.data);
      }, 1000);
    },
    getBarberShopError(error) {
      console.log("<<<<<<ERROR>>>>>>>", error);
    },
  };

  const presenter = getBarberShopDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    presenter.getBarberShopById(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (barberShop !== undefined) {
      setOwner(barberShop.owner);
      setAddress(barberShop.branch_offices_address);
    }
  }, [barberShop]);

  return (
    <div className="barbershops-detail-page">
      {barberShop !== undefined &&
      owner !== undefined &&
      address !== undefined ? (
        <div className="barbershops-detail-main-container">
          <TableContainer
            component={Paper}
            className="barbershops-detail-table-container"
          >
            <Table className="barbershops-detail-table">
              <TableHead className="barbershops-detail-table-header">
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="barbershops-detail-table-title"
                  >
                    {barberShop.name}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="barbershops-detail-table-body">
                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Dueño
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {owner && owner.fullname}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Teléfono
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {barberShop.phone}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Ubicación
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {address &&
                      `${address.address}, ${address.locality} - ${address.province}, ${address.country}`}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Horarios turno mañana
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {barberShop.morning}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Horarios turno tarde
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {barberShop.afternoon}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Alta en el sistema
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {new Date(barberShop.created_at).toLocaleDateString()}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="barbershops-detail-cell-title">
                    Última modificación
                  </TableCell>
                  <TableCell className="barbershops-detail-cell-data">
                    {new Date(barberShop.updated_at).toLocaleDateString()}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="loading">
          <div className="loader-container">
            <div className="loader-img-container">
              <img width={275} src={logo} alt="logo" />
            </div>
            <Loader />
          </div>
        </div>
      )}

      <div className="barbershops-detail-btn-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default BarberShopsDetail;
