import React, { useState, useEffect } from "react";
import "./styles.scss";
import RatesForm from "../../../../components/Forms/RatesForm";
import { getRatesCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

export const RatesCreate = () => {
  const [barberShops, setBarberShops] = useState([]);

  const viewHandlers = {
    getAllBarberShopsSuccess(data) {
      let barberShopsList = data.data.data;
      setBarberShops(
        barberShopsList.map((item) => ({
          value: item.id,
          title: item.name,
        }))
      );
    },
    getAllBarberShopsError(err) {
      console.log("<<<<<<ALL BARBERSHOPS ERROR>>>>>>>", err);
    },
    createRateSuccess(data) {
      console.log("<<<<<<CREATE RATE>>>>>>>", data.data.message);
    },
    createRateError(err) {
      console.log("<<<<<<CREATE RATE ERROR>>>>>>>", err);
    },
  };

  const presenter = getRatesCreatePresenter(viewHandlers);

  const createNewRate = (data) => {
    presenter.createRate(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllBarberShops();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="rates-create-screen">
      <div className="rates-create-form-container">
        <RatesForm create={createNewRate} barberShops={barberShops} />
      </div>
    </div>
  );
};

export default RatesCreate;
