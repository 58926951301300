import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import { Divider, Typography } from "@mui/material";
import { CustomButton } from "../../../../components/UI/CustomButton/index";
import { getBarbershopAddBarberPresenter } from "../../infrastructure/presentation/presenterProvider";
import BarbersSelector from "../../components/BarbersSelector";
import PersonAddAlt1Icon from "@mui/icons-material/PersonAddAlt1";
import CommissionSlider from "../../components/CommissionSlider";

const columns = [
  {
    field: "id",
    headerName: "ID",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    width: 40,
  },
  {
    field: "name",
    headerName: "Nombre",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "lastname",
    headerName: "Apellido",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "document_number",
    headerName: "DNI",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
  },
  {
    field: "status",
    headerName: "Comisión",
    headerClassName: "barbers-table-header",
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
  },
];

export const BarberShopsAddBarber = () => {
  const { id } = useParams();
  const [barberShop, setBarberShop] = useState(null);
  const [barbers, setBarbers] = useState([]);
  const [selectedBarber, setSelectedBarber] = useState({});
  const [commission, setCommission] = useState(0);
  const [error, setError] = useState(false);
  const [sliderError, setSliderError] = useState(false);

  const viewHandlers = {
    getBarberShopSuccess(data) {
      console.log("<<<<<BARBERSHOP>>>>>", data.statusText);
      setBarberShop(data.data.data);
    },
    getBarberShopError(err) {
      console.log("<<<<<BARBERSHOP ERR>>>>>", err);
    },
    getBarbersSuccess(data) {
      console.log("<<<<<BARBERS>>>>>", data.statusText);
      setBarbers(data.data.data);
    },
    getBarbersError(err) {
      console.log("<<<<<BARBERS ERR>>>>>", err.response.statusText);
    },
  };

  const presenter = getBarbershopAddBarberPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getBarberShop(id);
    presenter.getBarbers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addBarberToBarbershop = () => {
    if (JSON.stringify(selectedBarber) === "{}") {
      setError(true);
    } else if (commission <= 0 || commission > 100) {
      setSliderError(true);
    } else {
      console.log("ID BARBER: ", selectedBarber.id);
      console.log("ID BARBERSHOP: ", barberShop.id);
      console.log("COMMISSION: ", commission);
    }
  };

  return (
    <div className="add-barber-main-container">
      <div className="add-barber-title-container">
        <Typography variant="h4" gutterBottom component="div">
          {barberShop && barberShop.name}
        </Typography>
      </div>
      <div className="add-barber-section-container">
        <h3 className="add-barber-section-title">
          {`Seleccione un barbero para añadir a la sucursal ${
            barberShop && barberShop.name
          }`}
        </h3>
        <div className="add-barber-content-container">
          <BarbersSelector
            barbers={barbers}
            error={error}
            setError={setError}
            setSelectedBarber={setSelectedBarber}
          />
        </div>
      </div>
      <div className="add-barber-section-container">
        <h3 className="add-barber-section-title">
          {`Para poder añadir a ${
            JSON.stringify(selectedBarber) !== "{}"
              ? selectedBarber.name
              : "un barbero"
          } a la sucursal ${
            barberShop && barberShop.name
          } debe indicar el porcentaje de comisión por el cual va a trabajar`}
        </h3>
        <div className="add-barber-content-container">
          <CommissionSlider
            commission={commission}
            setCommission={setCommission}
            error={sliderError}
            setError={setSliderError}
          />
        </div>
      </div>
      <div className="add-barber-section-container">
        <div className="add-barber-button-container">
          <CustomButton
            onClick={() => addBarberToBarbershop()}
            variant="outlined"
            icon={<PersonAddAlt1Icon />}
            color="#FFC107"
            textColor={"black"}
          >
            Añadir
          </CustomButton>
        </div>
      </div>
      <Divider
        sx={{
          mt: { xs: 3, sm: 3, md: 5, lg: 5 },
          mb: { xs: 3, sm: 3, md: 5, lg: 5 },
          width: "100%",
        }}
        variant="middle"
        color={"#ffc107"}
      />
      <div className="barbers-table-container">
        <div className="barbers-table-title-container">
          <Typography variant="h5" gutterBottom component="div">
            Listado de barberos actualmente trabajando
          </Typography>
        </div>
        <div className="barbers-table-inner-container" style={{ height: 350 }}>
          <DataGrid
            className="barbers-table"
            disableColumnFilter
            disableColumnSelector
            columns={columns}
            rows={barbers && barbers}
          />
        </div>
      </div>
    </div>
  );
};

export default BarberShopsAddBarber;
