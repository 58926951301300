import "./styles.scss";
import React, { useEffect } from "react";
import { getSellersCreatePresenter } from "./../../infrastructure/presentation/presenterProvider";
import SellerForm from "../../../../components/Forms/SellerForm";

export const SellersCreate = () => {
  const viewHandlers = {
    createSellerSucces(data) {
      console.log("<<<<<CREATE SELLER DATA>>>>>", data.statusText);
    },
    createSellerError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getSellersCreatePresenter(viewHandlers);

  const createSeller = (data) => {
    presenter.createSeller(data);
  };

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-sellers-screen">
      <div className="create-sellers-form-container">
        <SellerForm create={createSeller} />
      </div>
    </div>
  );
};

export default SellersCreate;
