import axios from "axios";

export const HttpDeleteOwnerGateway = () => {
  return {
    deleteOwner: async (id) => {
      return axios.delete(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/owner/delete/id/${id}`
      );
    },
  };
};
