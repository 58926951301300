import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams } from "react-router-dom";
import { getBarberShopUpdatePresenter } from "../../infrastructure/presentation/presenterProvider";
import BarberShopsForm from "../../../../components/Forms/BarbershopsForm";

export const BarberShopsEdit = () => {
  const { id } = useParams();
  const [barberShop, setBarberShop] = useState({});
  const [owners, setOwners] = useState([]);

  const viewHandlers = {
    getAllOwnersSuccess(data) {
      let ownersList = data.data.data;
      setOwners(
        ownersList.map((item) => ({
          value: item.id,
          title: item.fullname,
        }))
      );
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR OWNERS>>>>>>>", err);
    },
    getBarberShopUpdSuccess(data) {
      setBarberShop(data.data.data);
    },
    getBarberShopUpdError(err) {
      console.log("<<<<<<BARBERSHOP ERROR>>>>>>>", err);
    },
    updateBarberShopSuccess(data) {
      console.log("<<<<<<UPDATE BARBERSHOP DATA>>>>>>>", data.statusText);
    },
    updateBarberShopError(err) {
      console.log("<<<<<<UPDATE BARBERSHOP ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberShopUpdatePresenter(viewHandlers);

  const editBarberShop = (data) => {
    presenter.updateBarberShop(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
    presenter.getBarberShopUpd(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="edit-barbershop-screen">
      <div className="edit-barbershop-form-container">
        <BarberShopsForm
          id={id}
          update={editBarberShop}
          barberShop={barberShop}
          owners={owners}
        />
      </div>
    </div>
  );
};

export default BarberShopsEdit;
