import React, { useState, useEffect } from "react";
import BranchOfficeSelector from "../../components/branchOfficeSelector";
import DailySummary from "../DailySummary";
import "./styles.scss";
import { Box, Tab, Tabs } from "@mui/material";
import TabPanel from "../../components/TabPanel/TabPanel";
import BarbersSummary from "../BarbersSummary";
import GlobalSummary from "../GlobalSummary";
import { getMetricsHomePresenter } from "../../infrastructure/presentation/presenterProvider";
import MonthlySummary from "../MonthlySummary";

const allyProps = (index) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

const MetricsHome = () => {
  const [branchOffices, setBranchOffices] = useState([]);
  const [value, setValue] = useState(0);
  const [selected, setSelected] = useState(null);

  const viewHandlers = {
    getAllBranchOfficesSuccess(data) {
      console.log("<<<<<<BRANCH OFFICES>>>>>>>", data.statusText);
      setBranchOffices(data.data.data);
    },
    getAllBranchOfficesError(err) {
      console.log("<<<<<<BRANCH OFFICES ERROR>>>>>>>", err);
    },
  };

  const presenter = getMetricsHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllBranchOffices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {}, [selected]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="metrics-screen">
      <div className="metrics-main-container">
        <div className="metrics-header-container">
          <h2 className="metrics-title">Métricas</h2>
          <div className="metrics-select-container">
            <BranchOfficeSelector
              options={branchOffices}
              selected={selected}
              setSelected={setSelected}
            />
          </div>
        </div>
        {selected && (
          <div className="metrics-inner-container">
            <div className="selected-title-container">
              <h3 className="selected-title">{selected.name}</h3>
            </div>
            <Box className="metrics-tabs-container">
              <Box className="metrics-tabs-buttons">
                <Tabs
                  className="metrics-tabs-buttons-nav"
                  value={value}
                  onChange={handleChange}
                  aria-label="tabs"
                  variant="scrollable"
                >
                  <Tab
                    className="metrics-tab"
                    label="Barberos"
                    {...allyProps(0)}
                  />
                  <Tab
                    className="metrics-tab"
                    label="Resumen semanal"
                    {...allyProps(1)}
                  />
                  <Tab
                    className="metrics-tab"
                    label="Resumen mensual"
                    {...allyProps(2)}
                  />
                  <Tab
                    className="metrics-tab"
                    label="Resumen global"
                    {...allyProps(3)}
                  />
                </Tabs>
              </Box>
              <TabPanel value={value} index={0} className="metrics-tabpanel">
                <BarbersSummary />
              </TabPanel>
              <TabPanel value={value} index={1} className="metrics-tabpanel">
                <DailySummary branchOffice={selected} />
              </TabPanel>
              <TabPanel value={value} index={2} className="metrics-tabpanel">
                <MonthlySummary branchOffice={selected} />
              </TabPanel>
              <TabPanel value={value} index={3} className="metrics-tabpanel">
                <GlobalSummary branchOffice={selected} />
              </TabPanel>
            </Box>
          </div>
        )}
      </div>
    </div>
  );
};

export default MetricsHome;
