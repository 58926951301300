import React, { useEffect, useState } from "react";
import "./styles.scss";
import { Divider, Grid } from "@mui/material";
import YearSelector from "../../components/yearSelector";
import { PieChart } from "../../../../components/UI/PieChart/PieChart";
import { getMonthlySummaryPresenter } from "../../infrastructure/presentation/presenterProvider";

const translatedMonths = {
  Jan: "Enero",
  Feb: "Febrero",
  Mar: "Marzo",
  Apr: "Abril",
  May: "Mayo",
  Jun: "Junio",
  Jul: "Julio",
  Aug: "Agosto",
  Sep: "Septiembre",
  Oct: "Octubre",
  Nov: "Noviembre",
  Dec: "Diciembre",
};

const MonthlySummary = ({ branchOffice }) => {
  const [year, setYear] = useState("2023");
  const [cutsByMonth, setCutsByMonth] = useState([]);
  const [pieLabels, setPieLabels] = useState([]);
  const [pieValues, setPieValues] = useState([]);
  const [totalCuts, setTotalCuts] = useState(0);

  const viewHandlers = {
    getCutsByMonthSuccess(data) {
      console.log("<<<<<<CUTS BY MONTH>>>>>>>", data.statusText);
      setCutsByMonth(data.data.data);
    },
    getCutsByMonthError(err) {
      console.log("<<<<<<CUTS BY MONTH ERROR>>>>>>>", err);
    },
  };

  const presenter = getMonthlySummaryPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    branchOffice
      ? presenter.getCutsByMonth(year, branchOffice.id)
      : presenter.getCutsByMonth(year);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [year, branchOffice]);

  useEffect(() => {
    let labels = [];
    let cuts = [];
    let auxTotalCuts = 0;
    // eslint-disable-next-line array-callback-return
    cutsByMonth.map((element) => {
      if (element.date.includes(year)) {
        labels.push(translatedMonths[element.month_name]);
        cuts.push(element.total_cuts);
        auxTotalCuts += Number(element.total_cuts);
      }
    });
    setPieLabels(labels);
    setPieValues(cuts);
    setTotalCuts(auxTotalCuts);
  }, [cutsByMonth, year]);

  return (
    <div className="monthly-summary-screen">
      <Grid container className="monthly-summary-grid">
        <Grid item xs={12} sm={12} className="monthly-summary-grid-item">
          <div className="year-selector-container">
            <YearSelector year={year} setYear={setYear} />
          </div>
        </Grid>
        {cutsByMonth.length >= 1 ? (
          <>
            <Grid item xs={12} sm={12} className="monthly-summary-grid-item">
              <PieChart
                title={"Cantidad cortes por mes"}
                labels={pieLabels}
                scores={pieValues}
                legend={true}
                tooltips={true}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="monthly-summary-grid-item"
            >
              <div className="monthly-summary-data-container">
                <h6 className="monthly-summary-data-title">
                  Cantidad total de cortes
                </h6>
                <Divider
                  variant="middle"
                  color={"#FFC107"}
                  sx={{ width: "90%" }}
                />
                <p className="monthly-summary-data">
                  {totalCuts ? `${totalCuts} cortes` : "0"}
                </p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="monthly-summary-grid-item"
            >
              <div className="monthly-summary-data-container">
                <h6 className="monthly-summary-data-title">
                  Pagos en efectivo
                </h6>
                <Divider
                  variant="middle"
                  color={"#FFC107"}
                  sx={{ width: "90%" }}
                />
                <p className="monthly-summary-data">falta data</p>
              </div>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              className="monthly-summary-grid-item"
            >
              <div className="monthly-summary-data-container">
                <h6 className="monthly-summary-data-title">Pagos en digital</h6>
                <Divider
                  variant="middle"
                  color={"#FFC107"}
                  sx={{ width: "90%" }}
                />
                <p className="monthly-summary-data">falta data</p>
              </div>
            </Grid>
          </>
        ) : (
          <Grid item xs={12} sm={12} className="monthly-summary-grid-item">
            <h3>No hay información disponible del año seleccionado</h3>
          </Grid>
        )}
      </Grid>
    </div>
  );
};

export default MonthlySummary;
