export const GlobalSummaryPresenter = (getCustomersByGenderGateway, viewHandlers) => {
  return {
    present() {
      console.log("GlobalSummary was rendered!");
    },
    getCustomersByGender(queryData) {
      const getCustomersByGenderPromise =
        getCustomersByGenderGateway.getCustomersByGender(queryData);

      getCustomersByGenderPromise
        .then((data) => {
          viewHandlers.getCustomersByGenderSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCustomersByGenderError(err);
        });
    },
  };
};
