import axios from "axios";

export const HttpUpdateBarberShopGateway = () => {
  return {
    updateBarberShop: async (data) => {
      const { id, owner_id, name, phone } = data;
      const body = { id, owner_id, name, phone };
      return axios.put(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/branch-office/update`,
        body
      );
    },
  };
};