import React from "react";
import "./styles.scss";
import { FormControl, InputLabel, MenuItem, Select, Typography } from "@mui/material";

const BranchOfficeSelector = ({ options, selected, setSelected }) => {
  const generateOptions = () => {
    return options.map((option, index) => {
      return (
        <MenuItem key={index} value={option.id}>
          {option.name}
        </MenuItem>
      );
    });
  };

  const handleChange = (evt) => {
    const data = options.find((item) => item.id === evt.target.value);
    setSelected(data);
  };

  return (
    <div className="custom-selector">
      <Typography variant="body2" className="custom-selector-title">
        Seleccione una sucursal
      </Typography>
      <div className="custom-selector-main-container">
        <FormControl className="custom-selector">
          <InputLabel id="custom-selector-label">Sucursales</InputLabel>
          <Select
            labelId="custom-selector-label"
            id="custom-selector-select"
            className="custom-selector-select"
            value={selected ? selected : ""}
            onChange={handleChange}
            fullWidth
            label="Sucursales"
            renderValue={(selected) => {
              if (selected) {
                return selected.name;
              } else {
                return <em>Seleccione una sucursal</em>;
              }
            }}
          >
            {generateOptions()}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default BranchOfficeSelector;
