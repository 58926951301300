import React from "react";
import "./styles.scss";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Box, TextField } from "@mui/material";

const YearSelector = ({ year, setYear }) => {
  const handleChange = (evt) => {
    try {
      setYear(evt.$y.toString());
    } catch (err) {
      console.log("Err: ", err);
    }
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box className="year-selector-main-container">
        <DatePicker
          label={"Seleccione un año"}
          views={["year"]}
          onChange={handleChange}
          value={year}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default YearSelector;
