/* Gateways */
import { HttpGetAllBarberShopsGateway } from "../gateways/HttpGetAllBarberShopsGateway";
import { HttpGetBarberShopGateway } from "../gateways/HttpGetBarberShopGateway";
import { HttpCreateBarberShopGateway } from "../gateways/HttpCreateBarberShopGateway";
import { HttpUpdateBarberShopGateway } from "../gateways/HttpUpdateBarberShopGateway";
import { HttpDeleteBarberShop } from "../gateways/HttpDeleteBarberShop";
import { HttpGetAllOwners } from "../gateways/HttpGetAllOwners";
import { HttpGetAllBarbersGateway } from "../gateways/HttpGetAllBarbersGateway";

/* Presenters */
import { BarberShopsHomePresenter } from "./BarberShopsHomePresenter";
import { BarberShopsDetailPresenter } from "./BarberShopDetailPresenter";
import { BarberShopCreatePresenter } from "./BarberShopCreatePresenter";
import { BarberShopUpdatePresenter } from "./BarberShopUpdatePresenter";
import { BarberShopDeletePresenter } from "./BarberShopDeletePresenter";
import { BarberShopAddBarberPresenter } from "./BarberShopAddBarberPresenter";

/* Trae todas las barberías */
export const getBarberShopsHomePresenter = (viewHandlers) => {
  const getAllBarberShopsGateway = HttpGetAllBarberShopsGateway();
  return BarberShopsHomePresenter(getAllBarberShopsGateway, viewHandlers);
};

/* Trae una barbería por id */
export const getBarberShopDetailPresenter = (viewHandlers) => {
  const getBarberShopGateway = HttpGetBarberShopGateway();
  return BarberShopsDetailPresenter(getBarberShopGateway, viewHandlers);
};

/* Crea una nueva barbería */
export const getBarberShopCreatePresenter = (viewHandlers) => {
  const getCreateBarberShopGateway = HttpCreateBarberShopGateway();
  const getAllOwnersGateway = HttpGetAllOwners();
  return BarberShopCreatePresenter(
    getCreateBarberShopGateway,
    getAllOwnersGateway,
    viewHandlers
  );
};

/* Actualiza una barbería existente */
export const getBarberShopUpdatePresenter = (viewHandlers) => {
  const getUpdateBarberShopGateway = HttpUpdateBarberShopGateway();
  const getBarberShopGateway = HttpGetBarberShopGateway();
  const getAllOwnersGateway = HttpGetAllOwners();
  return BarberShopUpdatePresenter(
    getUpdateBarberShopGateway,
    getBarberShopGateway,
    getAllOwnersGateway,
    viewHandlers
  );
};

/* Elimina una barbería */
export const getBarberShopDeletePresenter = (viewHandlers) => {
  const getDeleteBarberShopGateway = HttpDeleteBarberShop();
  return BarberShopDeletePresenter(getDeleteBarberShopGateway, viewHandlers);
};

export const getBarbershopAddBarberPresenter = (viewHandlers) => {
  const getBarberShopGateway = HttpGetBarberShopGateway();
  const getBarbersGateway = HttpGetAllBarbersGateway();
  return BarberShopAddBarberPresenter(
    getBarberShopGateway,
    getBarbersGateway,
    viewHandlers
  );
};
