import axios from "axios";

export const HttpCreateClientGateway = () => {
  return {
    createClient: async (data) => {
      const { name, lastname, email, document_number, age, gender } = data;
      const body = {
        name,
        lastname,
        email,
        document_number,
        age: Number(age),
        gender,
      };

      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/customer/create`,
        body
      );
    },
  };
};
