import axios from "axios";

export const HttpLoginGateway = () => {
  return {
    logIn: async (data) => {
      const { document_number, password, type } = data;
      const body = {
        document_number,
        password,
        type,
      };
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/login/${type}`,
        body
      );
    },
  };
};
