import React from "react";
import "./styles.scss";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { Button, Typography } from "@mui/material";

function DataTable({ category, rows, columns, buttonTitle }) {
  const navigate = useNavigate();

  function CustomToolbar() {
    return (
      <div className="custom-toolbar-container">
        <GridToolbarContainer className="grid-toolbar-container">
          <div className="grid-toolbar-btn-container">
            <Button
              startIcon={<AddCircleOutlineIcon fontSize="large" className='create-btn-icon'/>}
              onClick={() => navigate(`/${category}/add`)}
              className="create-btn"
            >
              <Typography className='create-btn-text'>{buttonTitle}</Typography>
            </Button>
          </div>
          <div className="grid-toolbar-searchbar-container">
            <GridToolbarQuickFilter className="search-bar" />
          </div>
        </GridToolbarContainer>
      </div>
    );
  }

  return (
    <div className={"data-grid-container"}>
      <DataGrid
        className="data-table"
        rows={rows}
        columns={columns}
        pageSize={5}
        align="center"
        disableColumnFilter
        disableColumnSelector
        components={{ Toolbar: CustomToolbar }}
        componentsProps={{
          toolbar: {
            showQuickFilter: true,
            quickFilterProps: { debounceMs: 500 },
            buttonTitle,
          },
        }}
      />
    </div>
  );
}

export default DataTable;
