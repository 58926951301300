import React, { useState, useEffect } from "react";
import "./styles.scss";
import { getBarberShopCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import BarberShopsForm from "../../../../components/Forms/BarbershopsForm";

export const BarberShopsCreate = () => {
  const [owners, setOwners] = useState([]);

  const viewHandlers = {
    createBarberShopSuccess(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
    },
    createBarberShopError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
    getAllOwnersSuccess(data) {
      let ownersList = data.data.data;
      setOwners(
        ownersList.map((item) => ({
          value: item.id,
          title: item.fullname,
        }))
      );
    },
    getAllOwnersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getBarberShopCreatePresenter(viewHandlers);

  //TODO consultar sobre el campo password de cada barbería, por ahora, en el body que se envía en la request está seteada para que sea un string vacío

  const createNewBarberShop = (data) => {
    presenter.createBarberShop(data);
  };

  useEffect(() => {
    presenter.present();
    presenter.getAllOwners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-barbershop-screen">
      <div className="create-barbershop-form-container">
        <BarberShopsForm create={createNewBarberShop} owners={owners} />
      </div>
    </div>
  );
};

export default BarberShopsCreate;
