export const MetricsHomePresenter = (
  getAllBranchOfficesGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Metrics Home was rendered!");
    },
    getAllBranchOffices() {
      const getAllBranchOfficesPromise =
        getAllBranchOfficesGateway.getAllbranchOffices();

      getAllBranchOfficesPromise
        .then((data) => {
          viewHandlers.getAllBranchOfficesSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getAllBranchOfficesError(err);
        });
    },
  };
};
