import { Box, Grid } from "@mui/material";
import React from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import CustomDatePicker from "../../../../components/UI/DatePicker/CustomDatePicker";
import { CustomButton } from "../../../../components/UI/CustomButton";
import CustomSelect from "../../../../components/UI/CustomSelect";
import { useNavigate } from "react-router-dom";

const BillsForm = ({ owners, setFromDate, setToDate, setOwner }) => {
  const { handleSubmit, control, getValues, clearErrors } = useForm();
  const navigate = useNavigate();
  const onSubmit = (data) => {
    setOwner(data.owner);
    setFromDate(data.from_date.$d.toLocaleDateString());
    setToDate(data.to_date.$d.toLocaleDateString());
    const queryData = {
      owner: data.owner,
      fromDate: data.from_date.$d.toLocaleDateString(),
      toDate: data.to_date.$d.toLocaleDateString(),
    };
    navigate("/bills/detail", { state: { queryData: queryData } });
  };

  return (
    <div className="bills-form-main-container">
      <Box className="bills-select-container">
        <Grid container className="select-grid-container">
          <Grid item xs={12} md={12} className="grid-select-item">
            <CustomSelect
              name="owner"
              control={control}
              label="Dueño"
              options={owners}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
      <div className="date-range-title">
        Seleccione rango de fechas para filtrar la información mensualmente
      </div>
      <Box className="bills-grid-container">
        <Grid container spacing={2} className="bills-form-grid">
          <Grid item xs={12} md={12} mb={2} className="bills-form-grid-item">
            <CustomDatePicker
              name="from_date"
              disabled={false}
              control={control}
              label="Desde"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                validate: (value) => value <= getValues("to_date"),
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} mb={2} className="bills-form-grid-item">
            <CustomDatePicker
              name="to_date"
              disabled={false}
              control={control}
              label="Hasta"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                validate: (value) =>
                  value >= getValues("from_date") ||
                  "Rango ingresado no válido",
              }}
            />
          </Grid>
          <Grid item xs={12} md={12} className="grid-button-container">
            <div className="grid-button">
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant="text"
                color="#FFC107"
                textColor={"black"}
              >
                Buscar
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default BillsForm;
