import axios from "axios";

export const HttpGetCustomersByGenderGateway = () => {
  return {
    getCustomersByGender: async (queryData) => {
      const { owner_id, from_date, to_date, id } = queryData;
      const body = {
        owner_id: Number(owner_id),
        from_date: from_date,
        to_date: to_date,
        id: Number(id) || 0,
      };
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/customer/get-customers-by-gender/${body.owner_id}/${body.from_date}/${body.to_date}/${body.id}`
      );
    },
  };
};
