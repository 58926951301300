import React, { useEffect } from "react";
import "./styles.scss";
import useUser from "../../../../hooks/useUser";
import { useLocation, useNavigate } from "react-router-dom";
import { getLoginPresenter } from "../../infrastructure/presentation/presenterProvider";
import LoginHeader from "../../components/LoginHeader";
import LoginForm from "../../../../components/Forms/LoginForm";

export const Login = () => {
  const { login, isLoading, isLogged, hasLoginError } = useUser();
  const navigate = useNavigate();
  const location = useLocation();

  const viewHanlders = {
    loginSuccess(data) {
      console.log("<<<<<<LOGIN>>>>>>>", data.data.message);
      login(data.data);
    },
    loginError(err) {
      console.log("<<<<<<LOGIN ERROR>>>>>>>", err);
      login(err);
    },
  };

  const presenter = getLoginPresenter(viewHanlders);

  const handleLogin = (data) => {
    /* console.log("Ruta seleccionada: ", location.state.type); */
    presenter.logIn({ ...data, type: location.state.type });
  };

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLogged) navigate("/home");
  }, [isLogged, navigate]);

  return (
    <div className="login-screen">
      <LoginHeader />
      <div className="login-form-container">
        <LoginForm
          handleLogin={handleLogin}
          loading={isLoading}
          hasLoginError={hasLoginError}
          role={location.state.type}
        />
      </div>
    </div>
  );
};

export default Login;
