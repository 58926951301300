import "./styles.scss";
import React, { useEffect } from "react";
import OwnerForm from "../../../../components/Forms/OwnerForm";
import { getOwnersCreatePresenter } from "../../infrastructure/presentation/presenterProvider";

export const OwnerCreate = () => {
  const viewHandlers = {
    createOwnerSucces(data) {
      console.log("<<<<<CREATE OWNER DATA>>>>>", data.statusText);
    },
    createOwnerError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getOwnersCreatePresenter(viewHandlers);

  const createOwner = (data) => {
    presenter.createOwner(data);
  };

  useEffect(() => {
    presenter.present();
  });

  return (
    <div className="create-owners-screen">
      <div className="create-owners-form-container">
        <OwnerForm create={createOwner} />
      </div>
    </div>
  );
};

export default OwnerCreate;
