import React, { useEffect } from "react";
import "../../../styles/FormStyles/styles.scss";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { WeekMock, ShiftsMocks } from "../../../services/rates.services";
import CustomTextField from "../../UI/CustomTextInput";
import CustomSelect from "../../UI/CustomSelect";
import { CustomButton } from "../../UI/CustomButton";

const RatesForm = ({ id, barberShops, create, update, updRate }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    if (updRate !== undefined) {
      setValue("branch_office_id", updRate.branch_office_id);
      setValue("dayWeek", updRate.dayWeek);
      setValue("partDay", updRate.partDay);
      setValue("typeCut", updRate.typeCut);
      setValue("flag", updRate.flag);
      setValue("intervalPrice", updRate.intervalPrice);
      setValue("maximum", updRate.maximum);
      setValue("comments", updRate.comments);
    }
  }, [updRate, setValue]);

  const onSubmit = (data) => {
    updRate === undefined ? create(data) : update({ ...data, id: Number(id) });
    navigate("/rates/all");
  };

  const resetForm = () => {
    reset({});
  };

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">
          {updRate === undefined ? "Crear tarifa" : "Editar tarifa"}
        </h3>
      </div>
      <Divider
        color="#FFC107"
        vairant="middle"
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomSelect
                name="branch_office_id"
                control={control}
                label="Sucursal"
                options={barberShops}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} lg={4} className="custom-form-grid-item-xs">
              <CustomSelect
                name="dayWeek"
                control={control}
                label="Día"
                options={WeekMock}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item md={4} lg={4} className="custom-form-grid-item-xs">
              <CustomSelect
                name="partDay"
                control={control}
                label="Turno"
                options={ShiftsMocks}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="typeCut"
                control={control}
                label="Tipo de trabajo"
                value={updRate !== undefined ? updRate.typeCut : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="flag"
                control={control}
                label="Bajada de bandera"
                value={updRate !== undefined ? updRate.flag : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="intervalPrice"
                control={control}
                label="Precio del intervalo"
                value={updRate !== undefined ? updRate.intervalPrice : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="maximum"
                control={control}
                label="Precio máximo"
                value={updRate !== undefined ? updRate.maximum : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="comments"
                control={control}
                label="Comentarios"
                value={updRate !== undefined ? updRate.commets : ""}
                type={"text"}
                multiline={true}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={resetForm}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {updRate === undefined ? "Crear" : "Actualizar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default RatesForm;
