import axios from "axios";

export const HttpGetAllOwnersGateway = () => {
  return {
    getAllOwners: async () => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/owner/get-all`
      );
    },
  };
};
