import React from "react";
import "./styles.scss";
import LoginHeader from "../../components/LoginHeader";
import { useParams } from "react-router-dom";
import { Roles } from "../../../../constants/roles";
import { getRegisterPresenter } from "../../infrastructure/presentation/presenterProvider";
import BarberForm from "../../../../components/Forms/BarberForm";
import OwnerForm from "../../../../components/Forms/OwnerForm";
import SellerForm from "../../../../components/Forms/SellerForm";

export const Register = () => {
  const { role } = useParams();

  const viewHandlers = {
    registerSuccess(data) {
      console.log("<<<<<<Register>>>>>>>", data);
    },
    registerError(err) {
      console.log("<<<<<<Error>>>>>>>", err);
    },
  };

  const presenter = getRegisterPresenter(viewHandlers);

  const handleRegister = (data) => {
    switch (role) {
      case Roles.Barber:
        presenter.registerBarber(data);
        return;
      case Roles.Owner:
        presenter.registerOwner(data);
        return;
      case Roles.Seller:
        presenter.registerSeller(data);
        return;
      default:
        return;
    }
  };

  return (
    <div className="register-screen">
      <LoginHeader />
      <div className="register-forms-container">
        {role === Roles.Barber ? (
          <BarberForm create={handleRegister} />
        ) : role === Roles.Owner ? (
          <OwnerForm create={handleRegister} />
        ) : (
          role === Roles.Seller && <SellerForm create={handleRegister} />
        )}
      </div>
    </div>
  );
};

export default Register;
