import React, { useState, useEffect } from "react";
import "./styles.scss";
import {
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { getSellersDetailPresenter } from "../../infrastructure/presentation/presenterProvider";
import Loader from "../../../../components/UI/Loader";
import logo from "../../../../assets/images/Artboard 1@1.5x.png";

export const SellersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [seller, setSellers] = useState({});

  const viewHandlers = {
    getSellerSucces(data) {
      setSellers(data.data.data);
      console.log("seller: ", seller);
    },
    getSellerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellersDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    presenter.getSeller(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="sellers-detail-screen">
      {JSON.stringify(seller) !== "{}" ? (
        <div className="sellers-detail-main-container">
          <TableContainer
            className="sellers-detail-table-container"
            component={Paper}
          >
            <Table className="sellers-detail-table">
              <TableHead className="sellers-detail-table-header">
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="sellers-detail-table-title"
                  >
                    Detalles del vendedor
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="sellers-detail-table-body">
                <TableRow>
                  <TableCell className="sellers-detail-cell-title">
                    Nombre completo
                  </TableCell>
                  <TableCell className="sellers-detail-cell-data">{`${seller.name} ${seller.lastname}`}</TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="sellers-detail-cell-title">
                    DNI
                  </TableCell>
                  <TableCell className="sellers-detail-cell-data">
                    {seller.document_number}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="sellers-detail-cell-title">
                    Email
                  </TableCell>
                  <TableCell className="sellers-detail-cell-data">
                    {seller.email}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="sellers-detail-cell-title">
                    Nombre de usuario
                  </TableCell>
                  <TableCell className="sellers-detail-cell-data">
                    {seller.username}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="loading">
          <div className="loader-container">
            <div className="loader-img-container">
              <img width={275} src={logo} alt="logo" />
            </div>
            <Loader />
          </div>
        </div>
      )}
      <div className="sellers-detail-back-btn-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
};

export default SellersDetail;
