import React, { useState, useEffect } from "react";
import "../../../styles/FormStyles/styles.scss";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { FormHelperText } from "@mui/material";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
} from "@mui/material";
import CustomTextField from "../../UI/CustomTextInput";
import { CustomButton } from "../../UI/CustomButton";

const LoginForm = ({ handleLogin, loading, hasLoginError, role }) => {
  const {
    handleSubmit,
    control,
    setError,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);

  const handleShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDown = (event) => {
    event.preventDefault();
  };

  const passwordRecovery = () => {
    console.log("A recuperar contraseña!");
  };

  const onSubmit = (data) => {
    handleLogin(data);
  };

  useEffect(() => {
    if (hasLoginError) {
      setError("document_number", {
        types: {
          invalid: "Error en las credenciales",
        },
      });
      setError("password", {
        types: {
          invalid: "Error en las credenciales",
        },
      });
    }
  }, [hasLoginError, setError]);

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">Iniciar sesión</h3>
      </div>
      <Divider
        variant="middle"
        color={"#FFC107"}
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="document_number"
                control={control}
                label={"Número de documento"}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    value: /\d+(,\d+)?$/i,
                    message: "Debe ingresar solo números",
                  },
                  minLength: {
                    value: 7,
                    message: "Ingrese un número de documento válido",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="password"
                control={control}
                label={"Contraseña"}
                type={showPassword ? "text" : "password"}
                placeHolder={"Username"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleShowPassword}
                      onMouseDown={handleMouseDown}
                      edge="end"
                    >
                      {showPassword ? (
                        <VisibilityOffOutlinedIcon />
                      ) : (
                        <VisibilityOutlinedIcon />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
              {hasLoginError && errors.email && (
                <FormHelperText error={true}>
                  {errors.email.types.invalid}
                </FormHelperText>
              )}
            </Grid>

            <Grid item className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={() => navigate(`/singin/${role}`)}
                  variant={"text"}
                  textColor={"black"}
                >
                  Crear cuenta
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {loading ? "Cargando..." : "Ingresar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  variant={"tiny"}
                  textColor={"gray"}
                  onClick={() => passwordRecovery()}
                >
                  Olvidé mi contraseña
                </CustomButton>
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default LoginForm;
