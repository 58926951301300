import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { Navs } from "../../services/services.navigation";
import HomeIcon from "@mui/icons-material/Home";
import { useNavigate } from "react-router-dom";
import Tooltip from "@mui/material/Tooltip";
import "./styles.scss";

const ListNested = ({ role }) => {
  const navigate = useNavigate();
  const open = true;
  const data = Navs.find((item) => item.role === role);

  return (
    <>
      <List>
        <ListItem disablePadding className="list-item">
          <ListItemButton
            onClick={() => navigate("/home")}
            className="list-item-button"
          >
            <Tooltip title="Home" placement="right">
              <ListItemIcon
                sx={{
                  mr: open ? 0.5 : "auto",
                }}
                className="list-item-button-icon"
              >
                <HomeIcon />
              </ListItemIcon>
            </Tooltip>
            <ListItemText
              primary="Home"
              sx={{ opacity: open ? 1 : 0 }}
              className="list-item-button-text"
            />
          </ListItemButton>
        </ListItem>

        {data &&
          data.routes.map((item) => (
            <ListItem disablePadding key={item.id} className="list-item">
              <ListItemButton
                onClick={() => navigate(item.url)}
                className="list-item-button"
              >
                <Tooltip title={item.title} placement="right">
                  <ListItemIcon
                    sx={{
                      mr: open ? 0.5 : "auto",
                    }}
                    className="list-item-button-icon"
                  >
                    {item.icon}
                  </ListItemIcon>
                </Tooltip>
                <ListItemText
                  primary={item.title}
                  sx={{ opacity: open ? 1 : 0 }}
                  className="list-item-button-text"
                />
              </ListItemButton>
            </ListItem>
          ))}
      </List>
    </>
  );
};

export default ListNested;
