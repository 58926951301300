import React, { useState, useEffect } from "react";
import "./styles.scss";
import { useParams, useNavigate } from "react-router-dom";
import { CustomButton } from "../../../../components/UI/CustomButton/index";
import Loader from "../../../../components/UI/Loader/index";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Divider } from "@mui/material";
import { getSellersDeletePresenter } from "./../../infrastructure/presentation/presenterProvider";

export const SellersDelete = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [openDialog, setOpenDialog] = useState(false);

  const viewHandlers = {
    deleteSellerSucces(data) {
      console.log("<<<<<<DELETE OWNER>>>>>>>", data.statusText);
    },
    deleteSellerError(err) {
      console.log("<<<<<<ERROR DELETE>>>>>>>", err);
    },
  };

  const presenter = getSellersDeletePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    setLoading(true);
    setOpenDialog(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  const handleClose = () => {
    presenter.deleteSeller(id);
    setOpenDialog(false);
    navigate("/sellers/all");
  };

  return (
    <div>
      <Dialog
        open={openDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {loading ? (
          <div className="loading-screen">
            <DialogContent className={"dialog-body"}>
              <div className="img-container">
                <img
                  width={275}
                  src={require("../../../../assets/images/Artboard 1@1.5x.png")}
                  alt="logo"
                />
              </div>

              <Divider variant="middle" color="#FFC107" />

              <div className="loader-container">
                <Loader />
              </div>
            </DialogContent>
          </div>
        ) : (
          <div className="confirm-text-container">
            <div className="title-container">
              <DialogTitle id="alert-dialog-title">
                Se ha eliminado el registro con éxito
              </DialogTitle>
            </div>
            <Divider variant="middle" color="#FFC107" />
            <DialogContent className="button-container">
              <DialogActions>
                <CustomButton
                  onClick={handleClose}
                  variant={"text"}
                  textColor={"black"}
                >
                  Aceptar
                </CustomButton>
              </DialogActions>
            </DialogContent>
          </div>
        )}
      </Dialog>
    </div>
  );
};

export default SellersDelete;
