import React from "react";
import "./styles.scss";
import { useForm } from "react-hook-form";
import CustomDatePicker from "../../../../components/UI/DatePicker/CustomDatePicker";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { Box, Grid } from "@mui/material";

const RangeDatePicker = ({ setFromDate, setToDate }) => {
  const { handleSubmit, control, getValues, clearErrors } = useForm();

  const onSubmit = (data) => {
    const { from_date, to_date } = data;
    setFromDate(
      new Date(from_date).toLocaleDateString().split("/").reverse().join("-")
    );
    setToDate(
      new Date(to_date).toLocaleDateString().split("/").reverse().join("-")
    );
  };

  return (
    <div className="range-date-picker-main-container">
      <div className="range-date-picker-title">
        Seleccione rango de fechas para filtrar la información
      </div>
      <Box className="range-date-picker-main-grid">
        <Grid container spacing={2} className="range-date-picker-form-grid">
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="range-date-picker-grid-item"
          >
            <CustomDatePicker
              name="from_date"
              disabled={false}
              control={control}
              label="Desde"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                valdate: (value) =>
                  value <= getValues("to_date") || "Rango ingresado no válido",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            lg={6}
            className="range-date-picker-grid-item"
          >
            <CustomDatePicker
              name="to_date"
              disabled={false}
              control={control}
              label="Hasta"
              value={undefined}
              defaultValue={null}
              clearErrors={clearErrors}
              rules={{
                required: {
                  value: true,
                  message: "Campo obligatorio",
                },
                validate: (value) =>
                  value >= getValues("from_date") ||
                  "Rango ingresado no válido",
              }}
            />
          </Grid>
          <Grid
            item
            xs={12}
            md={12}
            lg={12}
            className="range-date-picker-button-container"
          >
            <div className="grid-button">
              <CustomButton
                onClick={handleSubmit(onSubmit)}
                variant="outlined"
                color="#FFC107"
                textColor={"black"}
              >
                Buscar
              </CustomButton>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
};

export default RangeDatePicker;
