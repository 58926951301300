import axios from "axios";

export const HttpUpdateSellerGateway = () => {
  return {
    updateSeller: async (data) => {
      const { id, name, lastname, document_number, email, password, username } =
        data;
      const body = {
        id: Number(id),
        name,
        lastname,
        document_number,
        email,
        password,
        username,
      };
      return axios.put(`${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/seller/update`, body);
    },
  };
};
