// Gateways
import { HttpCreateClientGateway } from "../gateways/HttpCreateClientGateway";

// Presenters
import { CustomerCreatePresenter } from "./CustomerCreatePresenter";

export const getCustomerCreatePresenter = (viewHandlers) => {
  const getCustomerCreatePresenter = HttpCreateClientGateway();
  return CustomerCreatePresenter(getCustomerCreatePresenter, viewHandlers);
};
