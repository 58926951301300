import axios from "axios";

export const HttpGetCutsByMonth = () => {
  return {
    getCutsByMonth: async (year, id) => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/cut/get-cuts-by-month/${id}/${year}`
      );
    },
  };
};
