import PrivateRoute from "../utils/navigation";
import { Roles } from "./roles";

/* Owners Components */
import {
  OwnersHome,
  OwnerCreate,
  OwnersDetail,
  OwnersEdit,
  OwnersDelete,
} from "../modules/owners/index";

/* Componentes de barberos */
import {
  BarbersHome,
  BarberCreate,
  BarbersDetail,
  EditBarber,
  BarberAddBarberShops,
  DeleteBarber,
} from "../modules/barbers/index";

/* Componentes de Barberias */
import {
  BarberShopsHome,
  BarberShopsCreate,
  BarberShopsDetail,
  BarberShopsEdit,
  BarberShopsAddBarber,
  BarberShopsDelete,
} from "../modules/barbershops/index";

/* Componentes de Tarifas */
import {
  RatesHome,
  FilteredRates,
  RatesCreate,
  RatesDetail,
  RatesEdit,
  RatesDelete,
} from "../modules/rates/views/index";

/* Componentes de Vendedores */
import {
  SellersHome,
  SellersCreate,
  SellersDetail,
  SellersEdit,
  SellersDelete,
} from "../modules/sellers/index";

/* Componentes de Facturación */
import { BillsDashboard, BillsDetail } from "../modules/billing/index";

/* Perfil de barbero */
import PerformanceHome from "../modules/performance/views/PerformanceHome";

/* Perfil de vendedores */
import SalesHome from "../modules/sales/views/SalesHome";
import ModifyQR from "../modules/modifyQR/views/ModifyQRHome";
import MetricsHome from "../modules/metrics/views/MetricsHome";
import Home from "../modules/Home/views";

export const HOME = {
  path: "/home",
  element: (
    <PrivateRoute
      path="/home"
      roles={[
        Roles.Admin,
        Roles.Owner,
        Roles.Barber,
        Roles.Seller,
        Roles.Client,
      ]}
      Component={Home}
    />
  ),
};

/* Rutas de Owners */
export const OWNERS_ALL = {
  path: "/owners/all",
  element: (
    <PrivateRoute
      path="/owners/all"
      roles={[Roles.Admin, Roles.Seller]}
      Component={OwnersHome}
    />
  ),
};
export const OWNERS_ADD = {
  path: "/owners/add",
  element: (
    <PrivateRoute
      path="/owners/add"
      roles={[Roles.Admin, Roles.Seller]}
      Component={OwnerCreate}
    />
  ),
};
export const OWNERS_DETAIL = {
  path: "/owners/detail/:id",
  element: (
    <PrivateRoute
      path="/owners/detail/:id"
      roles={[Roles.Admin, Roles.Seller]}
      Component={OwnersDetail}
    />
  ),
};
export const OWNER_EDIT = {
  path: "/owners/edit/:id",
  element: (
    <PrivateRoute
      path="/owners/edit/:id"
      roles={[Roles.Admin, Roles.Seller]}
      Component={OwnersEdit}
    />
  ),
};
export const OWNER_DELETE = {
  path: "/owners/delete/:id",
  element: (
    <PrivateRoute
      path="/owners/delete/:id"
      roles={[Roles.Admin]}
      Component={OwnersDelete}
    />
  ),
};

export const METRICS = {
  path: "/metrics",
  element: (
    <PrivateRoute
      path="/metrics"
      roles={[Roles.Admin, Roles.Owner]}
      Component={MetricsHome}
    />
  ),
};

/* Rutas de Barbers */

export const BARBERS_ALL = {
  path: "/barbers/all",
  element: (
    <PrivateRoute
      path="/barbers/all"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarbersHome}
    />
  ),
};
export const BARBERS_ADD = {
  path: "/barbers/add",
  element: (
    <PrivateRoute
      path="/barbers/add"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarberCreate}
    />
  ),
};
export const BARBERS_DETAIL = {
  path: "/barbers/detail/:id",
  element: (
    <PrivateRoute
      path="/barbers/detail/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarbersDetail}
    />
  ),
};
export const BARBERS_EDIT = {
  path: "/barbers/edit/:id",
  element: (
    <PrivateRoute
      path="/barbers/edit/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={EditBarber}
    />
  ),
};

export const BARBERS_ADD_BARBERSHOP = {
  path: "/barbers/add-barber/:id",
  element: (
    <PrivateRoute
      path="/barbers/add-barber/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={BarberAddBarberShops}
    />
  ),
};

export const BARBERS_DELETE = {
  path: "/barbers/delete/:id",
  element: (
    <PrivateRoute
      path="/barbers/delete/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={DeleteBarber}
    />
  ),
};

/* Rutas de Barberías */

export const BARBERSHOPS_ALL = {
  path: "/barbershops/all",
  element: (
    <PrivateRoute
      path="/barbershops/all"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarberShopsHome}
    />
  ),
};
export const BARBERSHOPS_ADD = {
  path: "/barbershops/add",
  element: (
    <PrivateRoute
      path="/barbershops/add"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarberShopsCreate}
    />
  ),
};
export const BARBERSHOPS_DETAIL = {
  path: "/barbershops/detail/:id",
  element: (
    <PrivateRoute
      path="/barbershops/detail/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarberShopsDetail}
    />
  ),
};
export const BARBERSHOPS_EDIT = {
  path: "/barbershops/edit/:id",
  element: (
    <PrivateRoute
      path="/barbershops/edit/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={BarberShopsEdit}
    />
  ),
};

export const BARBERSHOPS_ADD_BARBER = {
  path: "/barbershops/add-barber/:id",
  element: (
    <PrivateRoute
      path="/barbershops/add-barber/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={BarberShopsAddBarber}
    />
  ),
};

export const BARBERSHOPS_DELETE = {
  path: "/barbershops/delete/:id",
  element: (
    <PrivateRoute
      path="/barbershops/delete/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={BarberShopsDelete}
    />
  ),
};

/* Rutas de tarifas */

export const RATES_ALL = {
  path: "/rates/all",
  element: (
    <PrivateRoute
      path="/rates/all"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={RatesHome}
    />
  ),
};
export const RATES_FILTERED = {
  path: "/rates/filter/:day/:shift",
  element: (
    <PrivateRoute
      path="/rates/filter/:day/:shift"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={FilteredRates}
    />
  ),
};
export const RATES_ADD = {
  path: "/rates/add",
  element: (
    <PrivateRoute
      path="/rates/add"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={RatesCreate}
    />
  ),
};
export const RATES_DETAIL = {
  path: "/rates/detail/:id",
  element: (
    <PrivateRoute
      path="/rates/detail/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={RatesDetail}
    />
  ),
};
export const RATES_EDIT = {
  path: "/rates/edit/:id",
  element: (
    <PrivateRoute
      path="/rates/edit/:id"
      roles={[Roles.Admin, Roles.Owner, Roles.Seller]}
      Component={RatesEdit}
    />
  ),
};
export const RATES_DELETE = {
  path: "/rates/delete/:id",
  element: (
    <PrivateRoute
      path="/rates/delete/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={RatesDelete}
    />
  ),
};

/* Rutas de Vendedores */
//TODO SACAR LOS PERMISOS DE LOS OWNERS

export const SELLERS_ALL = {
  path: "/sellers/all",
  element: (
    <PrivateRoute
      path="/sellers/all"
      roles={[Roles.Admin, Roles.Owner]}
      Component={SellersHome}
    />
  ),
};
export const SELLERS_ADD = {
  path: "/sellers/add",
  element: (
    <PrivateRoute
      path="/sellers/add"
      roles={[Roles.Admin, Roles.Owner]}
      Component={SellersCreate}
    />
  ),
};
export const SELLERS_DETAIL = {
  path: "/sellers/detail/:id",
  element: (
    <PrivateRoute
      path="/sellers/detail/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={SellersDetail}
    />
  ),
};
export const SELLERS_EDIT = {
  path: "/sellers/edit/:id",
  element: (
    <PrivateRoute
      path="/sellers/edit/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={SellersEdit}
    />
  ),
};
export const SELLERS_DELETE = {
  path: "/sellers/delete/:id",
  element: (
    <PrivateRoute
      path="/sellers/delete/:id"
      roles={[Roles.Admin, Roles.Owner]}
      Component={SellersDelete}
    />
  ),
};

/* Rutas de Facturación */
export const BILLS_HOME = {
  path: "/billing",
  element: (
    <PrivateRoute
      path="/billing"
      roles={[Roles.Admin]}
      Component={BillsDashboard}
    />
  ),
};

export const BILLS_DETAIL = {
  path: "/bills/detail",
  element: (
    <PrivateRoute
      path="/bills/detail"
      roles={[Roles.Admin]}
      Component={BillsDetail}
    />
  ),
};

export const PERFORMANCE_HOME = {
  path: "/performance",
  element: (
    <PrivateRoute
      path="/performance"
      roles={[Roles.Barber]}
      Component={PerformanceHome}
    />
  ),
};

export const MODIFY_QR = {
  path: "/modify-qr",
  element: (
    <PrivateRoute
      path="/modify-qr"
      roles={[Roles.Barber]}
      Component={ModifyQR}
    />
  ),
};

export const SALES_HOME = {
  path: "/sales",
  element: (
    <PrivateRoute path="/sales" roles={[Roles.Seller]} Component={SalesHome} />
  ),
};
