export const WeekMock = [
  /* {
    id: 7,
    title: "Todos los días",
    value: 7,
  }, */
  {
    id: 1,
    title: "Lunes",
    value: 1,
  },
  {
    id: 2,
    title: "Martes",
    value: 2,
  },
  {
    id: 3,
    title: "Miércoles",
    value: 3,
  },
  {
    id: 4,
    title: "Jueves",
    value: 4,
  },
  {
    id: 5,
    title: "Viernes",
    value: 5,
  },
  {
    id: 6,
    title: "Sábado",
    value: 6,
  },
  {
    id: 0,
    title: "Domingo",
    value: 0,
  },
];

export const ShiftsMocks = [
  {
    id: 1,
    title: "Mañana",
    value: 1,
  },
  {
    id: 2,
    title: "Tarde",
    value: 2,
  },
  /* {
    id: 3,
    title: "Mañana y tarde",
    value: 3,
  }, */
];
