export const BarberShopAddBarberPresenter = (
  getBarberShopGateway,
  getBarbersGateway,
  viewHandlers
) => {
  return {
    present() {
      console.log("Barbershop add barber was rendered!");
    },
    getBarberShop(id) {
      const getBarbershopPromise = getBarberShopGateway.getBarberShop(id);

      getBarbershopPromise
        .then((data) => {
          viewHandlers.getBarberShopSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarberShopError(err);
        });
    },
    getBarbers() {
      const getBarbersPromise = getBarbersGateway.getBarbers();

      getBarbersPromise
        .then((data) => {
          viewHandlers.getBarbersSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getBarbersError(err);
        });
    },
  };
};
