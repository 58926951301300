import React from "react";
import "./styles.scss";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const BarChart = ({ title, labels, scores, tooltipLabel, legend }) => {
  const options = {
    fill: true,
    animations: true,
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: legend,
      },
      title: {
        display: true,
        text: title,
        color: "#1e1e1e",
        font: {
          size: 22,
          family:
            "system-ui,-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue,sans-serif",
        },
      },
    },
  };

  const data = {
    labels,
    datasets: [
      {
        label: tooltipLabel ? tooltipLabel : "Cantidad",
        data: scores && scores,
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: /* "rgb(75, 192, 192)" */ "rgb(0, 0, 0)",
        borderWidth: 1,
        alignItems: "center",
        barPercentage: 0.7,
        maxBarThickness: 100,
      },
    ],
  };

  return (
    <div className="bar-chart-container">
      <div className="bar-chart-inner-container">
        <Bar data={data} options={options} className="bar-chart" />
      </div>
    </div>
  );
};

export default BarChart;
