import axios from "axios";

export const HttpGetAllBarbersGateway = () => {
  return {
    getBarbers: async () => {
      return axios.get(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/barber/get-all`
      );
    },
  };
};
