import axios from "axios";

export const HttpUpdateBarberGateway = () => {
  return {
    updateBarber: async (data) => {
      const {
        id,
        email,
        name,
        lastname,
        document_number,
        alias,
        qr,
      } = data;
      const body = {
        id,
        email,
        name,
        lastname,
        document_number,
        alias,
        qr,
      };
      return axios.put(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/barber/update`,
        body
      );
    },
  };
};
