import React, { useEffect } from "react";
import "./styles.scss";
import { getBarberCreatePresenter } from "../../infrastructure/presentation/presenterProvider";
import BarberForm from "../../../../components/Forms/BarberForm";

export const BarberCreate = () => {
  const viewHandlers = {
    createBarberSucces(data) {
      console.log("<<<<<DATA>>>>>", data.statusText);
    },
    createBarberError(err) {
      console.log("<<<<<ERROR>>>>>", err);
    },
  };

  const presenter = getBarberCreatePresenter(viewHandlers);

  const createBarber = (data) => {
    presenter.createBarber(data);
  };

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="create-barber-screen">
      <div className="create-barber-form-container">
        <BarberForm create={createBarber} />
      </div>
    </div>
  );
};

export default BarberCreate;
