import React, { useState } from "react";
import "./styles.scss";
import RangeDatePicker from "../../components/RangeDatePicker";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  Typography,
} from "@mui/material";
import { CustomButton } from "../../../../components/UI/CustomButton";

const cantBarbers = [1, 2, 3, 4, 5];

const BarbersSummary = () => {
  // eslint-disable-next-line no-unused-vars
  const [fromDate, setFromDate] = useState("");
  // eslint-disable-next-line no-unused-vars
  const [toDate, setToDate] = useState("");

  return (
    <div className="barbers-summary-main-container">
      <div className="barbers-summary-dates-container">
        <RangeDatePicker setFromDate={setFromDate} setToDate={setToDate} />
      </div>
      <div className="barbers-summary-grid-container">
        <Grid container className="barbers-summary-grid">
          {cantBarbers.map((id, index) => {
            return (
              <Grid
                item
                xs={12}
                md={4}
                lg={4}
                className="barbers-summary-grid-item"
                key={index}
              >
                <Card className="barbers-summary-data-container" elevation={5}>
                  <Box className="barbers-summary-card-title">
                    <Typography variant="subtitle1">{`Nombre Barbero ${id}`}</Typography>
                  </Box>
                  <CardContent className="barbers-summary-card-body">
                    <ul>
                      <li className="barbers-summary-data">Clientes: </li>
                      <li className="barbers-summary-data">Caja: $</li>
                      <li className="barbers-summary-data">Sillón: $</li>
                      <li className="barbers-summary-data">Barbero: $</li>
                      <li className="barbers-summary-data">
                        Corte promedio: $
                      </li>
                      <li className="barbers-summary-data">Tiempo promedio:</li>
                      <li className="barbers-summary-data">Efectivo: $</li>
                      <li className="barbers-summary-data">Digital: $</li>
                    </ul>
                  </CardContent>
                  <CardActions className="barbers-summary-card-btn-container">
                    <CustomButton
                      onClick={() => console.log("Al detalle del barbero?")}
                      variant="text"
                      color="#FFC107"
                      textColor={"black"}
                    >
                      {`Detalle ${id}`}
                    </CustomButton>
                  </CardActions>
                </Card>
              </Grid>
            );
          })}
        </Grid>
      </div>
    </div>
  );
};

export default BarbersSummary;
