import React, { useEffect, useState } from "react";
import "./styles.scss";
import ActionButtons from "../../../../components/ActionButtons/index";
import DataTable from "../../../../components/DataTable";
import { getSellersHomePresenter } from "./../../infrastructure/presentation/presenterProvider";

const columns = [
  {
    field: "id",
    headerName: "id",
    flex: 1,
    minWidth: 70,
    maxWidth: 120,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "name",
    headerName: "Nombre",
    flex: 1,
    minWidth: 120,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "lastname",
    headerName: "Apellido",
    flex: 1,
    minWidth: 120,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "email",
    headerName: "Email",
    flex: 1,
    minWidth: 220,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "username",
    headerName: "Usuario",
    flex: 1,
    minWidth: 150,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "document_number",
    headerName: "DNI",
    flex: 1,
    minWidth: 180,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Acciones",
    width: 300,
    headerClassName: "column-title",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ActionButtons
          data={params}
          category={"sellers"}
          title={"Vendedores"}
        />
      );
    },
  },
];

export const SellersHome = () => {
  const [sellers, setSellers] = useState([]);

  const viewHandlers = {
    getAllSellersSucces(data) {
      console.log("<<<<<<DATA>>>>>>>", data.statusText);
      setSellers(data.data.data);
    },
    getAllSellersError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getSellersHomePresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    presenter.getAllSellers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="sellersHome-main-screen">
      <div className="sellersHome-screen-title">Vendedores</div>
      <>
        <DataTable
          rows={sellers}
          columns={columns}
          buttonTitle={"Nuevo Vendedor"}
          category={"sellers"}
        />
      </>
    </div>
  );
};

export default SellersHome;
