import axios from "axios";

export const HttpCreateRateGateway = () => {
  return {
    createRate: async (data) => {
      const {
        branch_office_id,
        dayWeek,
        flag,
        intervalPrice,
        maximum,
        partDay,
        typeCut,
        comments,
      } = data;
      const body = {
        branch_office_id,
        dayWeek,
        flag: Number(flag),
        intervalPrice: Number(intervalPrice),
        maximum: Number(maximum),
        partDay,
        typeCut,
        comments,
      };
      return axios.post(`${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/rate/create`, body);
    },
  };
};
