import React from "react";
import "./styles.scss";

const Ranking = () => {
  return (
    <div className="ranking-screen">
      <h1>Ranking</h1>
    </div>
  );
};

export default Ranking;
