import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import ActionButtons from "../../../../components/ActionButtons";
import DataTable from "../../../../components/DataTable";
import { CustomButton } from "../../../../components/UI/CustomButton";
import { WeekMock, ShiftsMocks } from "../../../../services/rates.services";
import Loader from "../../../../components/UI/Loader/index";
import "./styles.scss";
import { getFilteredRatesPresenter } from "../../infrastructure/presentation/presenterProvider";
import NoDataCard from "../../components/NoDataCard";
import { Card, CardContent, Divider, Typography } from "@mui/material";

const columns = [
  {
    field: "id",
    headerName: "id",
    minWidth: 70,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "typeCut",
    headerName: "Tipo de trabajo",
    flex: 0.7,
    minWidth: 190,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "flag",
    headerName: "Bajada de Bandera",
    flex: 0.5,
    minWidth: 190,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "intervalPrice",
    headerName: "Precio de Intervalo",
    flex: 0.5,
    minWidth: 190,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "maximum",
    headerName: "Precio Máximo",
    flex: 0.5,
    minWidth: 190,
    headerClassName: "column-title",
    headerAlign: "center",
  },
  {
    field: "",
    headerName: "Acciones",
    flex: 0.8,
    minWidth: 200,
    headerClassName: "column-title",
    headerAlign: "center",
    renderCell: (params) => {
      return (
        <ActionButtons data={params} category={"rates"} title={"Tarifa"} />
      );
    },
  },
];

export const FilteredRates = () => {
  const { day, shift } = useParams();
  const navigate = useNavigate();
  const [rows, setRows] = useState([]);
  const [dayValue, setDayValue] = useState("");
  const [shiftValue, setShiftValue] = useState("");
  const [loadingScreen, setLoadingScreen] = useState(false);

  const viewHandlers = {
    getAllRatesSuccess(data) {
      console.log("<<<<<<FILTERED RATES>>>>>>>", data.statusText);
      let filteredRow = data.data.data.filter(
        (item) => item.dayWeek === Number(day) && item.partDay === Number(shift)
      );
      setRows(filteredRow);
    },
    getAllRatesError(err) {
      console.log("<<<<<<FILTERED RATES ERROR>>>>>>>", err);
    },
  };

  const presenter = getFilteredRatesPresenter(viewHandlers);

  useEffect(() => {
    setLoadingScreen(true);
    setTimeout(() => {
      setLoadingScreen(false);
    }, [2000]);
  }, []);

  useEffect(() => {
    presenter.present();
    presenter.getAllRates();
    WeekMock.filter((item) => item.id === Number(day) && setDayValue(item));
    ShiftsMocks.filter(
      (item) => item.id === Number(day) && setShiftValue(item)
    );
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="filtered-rates-main-screen">
      <div className="rates-screen-main-title">Tarifas</div>
      <div className="day-shift-data">
        <div>
          <span className="day-shift-title">Día:</span> {dayValue.title}
        </div>
        <div>
          <span className="day-shift-title">Turno:</span> {shiftValue.title}
        </div>
      </div>
      {loadingScreen ? (
        <div className="loader-container">
          <Card
            className="filtered-rates-loader-container"
            elevation={4}
            sx={{ minWidth: 275 }}
          >
            <CardContent className="filtered-rates-loader">
              <Typography variant="h5" component="div">
                Cargando...
              </Typography>
              <div className="filtered-rates-divider-container">
                <Divider variant="middle" color="#FFC107" />
              </div>
              <div>
                <Loader />
              </div>
            </CardContent>
          </Card>
        </div>
      ) : rows.length !== 0 ? (
        <div>
          <DataTable
            rows={rows}
            columns={columns}
            buttonTitle={"Nueva Tarifa"}
            category={"rates"}
          />
          <div className="all-button-container">
            <CustomButton
              onClick={() => navigate(-1)}
              variant="outlined"
              color="#FFC107"
              textColor={"black"}
            >
              Volver
            </CustomButton>
          </div>
        </div>
      ) : (
        <NoDataCard />
      )}
    </div>
  );
};

export default FilteredRates;
