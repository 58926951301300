import axios from "axios";

export const HttpCreateOwnerGateway = () => {
  return {
    createOwner: async (data) => {
      const {
        fullname,
        document_number,
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission,
        phone,
        contract_date,
      } = data;
      const body = {
        fullname,
        document_number,
        email,
        password,
        comment,
        address,
        locality,
        province,
        country,
        commission: Number(commission),
        phone,
        contract_date,
      };
      return axios.post(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/onboarding/register/owner`,
        body
      );
    },
  };
};
