import React, { useEffect } from "react";
import { Box, Divider, Grid, Stack } from "@mui/material";
import { useForm } from "react-hook-form";
import "../../../styles/FormStyles/styles.scss";
import { CustomButton } from "../../UI/CustomButton";
import CustomSelect from "../../UI/CustomSelect";
import CustomTextField from "../../UI/CustomTextInput";
import { useNavigate } from "react-router-dom";

const BarberShopsForm = ({ id, create, barberShop, owners, update }) => {
  const { handleSubmit, control, setValue, reset } = useForm();
  const navigate = useNavigate();

  const onSubmit = (data) => {
    barberShop === undefined
      ? create(data)
      : update({ ...data, id: Number(id) });
    navigate("/barbershops/all");
  };

  const onReset = () => {
    reset({});
  };

  useEffect(() => {
    //TODO consultar por el update de una barbershop, no se puede cambiar la dirección
    if (barberShop !== undefined) {
      console.log("barberShop", barberShop);
      setValue("owner_id", barberShop.owner_id);
      setValue("name", barberShop.name);
      setValue("address", barberShop.branch_offices_address?.address);
      setValue("locality", barberShop.branch_offices_address?.locality);
      setValue("province", barberShop.branch_offices_address?.province);
      setValue("country", barberShop.branch_offices_address?.country);
      setValue("phone", barberShop.phone);
    }
  }, [barberShop, setValue]);

  return (
    <div className="custom-form-main-container">
      <div className="custom-form-title-container">
        <h3 className="custom-form-title">
          {barberShop === undefined ? "Nueva sucursal" : "Editar sucursal"}
        </h3>
      </div>
      <Divider
        variant="middle"
        color="#ffc107"
        className="custom-form-separator"
      />
      <Box className="custom-form-container">
        <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
          <Grid container className="custom-form-grid">
            <Grid item className="custom-form-grid-item">
              <CustomSelect
                name="owner_id"
                control={control}
                label="Dueño"
                options={owners}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="name"
                control={control}
                label="Nombre Barbería"
                value={barberShop !== undefined ? barberShop.name : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="address"
                control={control}
                label="Dirección"
                value={barberShop !== undefined ? barberShop.address : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo Obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="locality"
                control={control}
                label="Localidad"
                value={barberShop !== undefined ? barberShop.locality : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="province"
                control={control}
                label="Provincia"
                value={barberShop !== undefined ? barberShop.province : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="country"
                control={control}
                label="País"
                value={barberShop !== undefined ? barberShop.country : ""}
                type={"text"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-grid-item">
              <CustomTextField
                name="phone"
                control={control}
                label="Teléfono"
                value={barberShop !== undefined ? barberShop.phone : ""}
                type={"number"}
                rules={{
                  required: {
                    value: true,
                    message: "Campo obligatorio",
                  },
                  pattern: {
                    pattern: /[\d+(,\d+)?]{10}$/i,
                    message: "El número ingresado no es válido",
                  },
                }}
              />
            </Grid>

            <Grid item className="custom-form-buttons-container">
              <Stack
                direction={{
                  xs: "column-reverse",
                  sm: "column-reverse",
                  md: "row",
                }}
                spacing={3}
                className="custom-form-buttons-stack"
              >
                <CustomButton
                  onClick={onReset}
                  variant={"text"}
                  textColor={"black"}
                >
                  Borrar
                </CustomButton>
                <CustomButton
                  onClick={handleSubmit(onSubmit)}
                  variant={"text"}
                  textColor={"black"}
                >
                  {barberShop === undefined ? "Crear" : "Actualizar"}
                </CustomButton>
              </Stack>
              <div className="custom-form-back-button-container">
                <CustomButton
                  onClick={() => navigate(-1)}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Volver
                </CustomButton>
              </div>
            </Grid>
          </Grid>
        </form>
      </Box>
    </div>
  );
};

export default BarberShopsForm;
