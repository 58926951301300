import React from "react";
import "./styles.scss";
import {
  Avatar,
  Card,
  CardContent,
  CardHeader,
  Divider,
} from "@mui/material";
import ContentCutIcon from "@mui/icons-material/ContentCut";

const CardData = () => {
  return (
    <Card className="performance-card" elevation={5} sx={{ maxWidth: 445 }}>
      <CardHeader
        avatar={
          <Avatar>
            <ContentCutIcon />
          </Avatar>
        }
        className="performance-card-header"
        title="Fecha o nombre del barbero"
        subheader="Nombre de la barbería"
      />
      <Divider variant="middle" className="performance-card-separator" />
      <CardContent className="performance-card-body">
        <div className="performance-table-container">
          <table className="performance-table">
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Clientes:</th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Caja:</th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Sillón: $</th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Barbero: $</th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Corte promedio: </th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Tiempo promedio: </th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Efectivo: $</th>
              <td className="performance-table-data">Value</td>
            </tr>
            <tr className="performance-table-row">
              <th className="performance-table-title">✓ Digital: $</th>
              <td className="performance-table-data">Value</td>
            </tr>
          </table>
        </div>
      </CardContent>
    </Card>
  );
};

export default CardData;
