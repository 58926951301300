import React from "react";
import "./styles.scss";
import { Controller } from "react-hook-form";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ErrorIcon from "@mui/icons-material/Error";
import {
  styled,
  Box,
  FormControl,
  InputLabel,
  Button,
  Input,
  Stack,
  FormHelperText,
} from "@mui/material";

const FileInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const UploadButton = ({ name, control, label, rules, convert, loaded }) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState: { error } }) => (
        <Box className="upload-button-main-container">
          <FormControl
            className="upload-button-form-control"
            fullWidth
            error={error !== undefined}
          >
            <Stack
              className="upload-inner-container"
              direction={"row"}
              alignItems={"center"}
              spacing={1}
            >
              <div className="upload-data-display">
                <InputLabel className={error ? 'upload-label-error' : "upload-label"} htmlFor="file-name-display" >{label}</InputLabel>
                <Input fullWidth readOnly value={loaded && loaded} id='file-name-display' />
              </div>
              <div className="upload-button-container">
                <Button
                    component="label"
                    variant="outlined"
                    endIcon={error ? <ErrorIcon className='error-icon'/>: <CloudUploadIcon className='normal-icon'/>}
                    className="upload-button"
                  >
                    Subir Archivo
                    <FileInput
                      type="file"
                      onChange={(evt) => {
                        convert(evt.target.files[0]);
                      }}
                    />
                </Button>
              </div>
            </Stack>
            <FormHelperText className="upload-button-helper-error">
              {error ? error.message : null}
            </FormHelperText>
          </FormControl>
        </Box>
      )}
    />
  );
};

export default UploadButton;
