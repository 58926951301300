export const MonthlySummaryPresenter = (viewHandlers, getCutsByMontGateway) => {
  return {
    present() {
      console.log("Monthly summary was rendered!");
    },
    getCutsByMonth(year, id) {
      const getCutsByMonthPromise = getCutsByMontGateway.getCutsByMonth(
        year,
        id
      );

      getCutsByMonthPromise
        .then((data) => {
          viewHandlers.getCutsByMonthSuccess(data);
        })
        .catch((err) => {
          viewHandlers.getCutsByMonthError(err);
        });
    },
  };
};
