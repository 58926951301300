import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import "./styles.scss";
import {
  TableContainer,
  Paper,
  TableHead,
  Table,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import logo from "../../../../assets/images/Artboard 1@1.5x.png";
import { CustomButton } from "../../../../components/UI/CustomButton";
import Loader from "../../../../components/UI/Loader";
import { useNavigate } from "react-router-dom";
import { getOwnersDetailPresenter } from "../../infrastructure/presentation/presenterProvider";

export const OwnersDetail = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [owner, setOwner] = useState({});

  const viewHandlers = {
    getOwnerSucces(data) {
      setTimeout(() => {
        setOwner(data.data.data);
      }, [1000]);
    },
    getOwnerError(err) {
      console.log("<<<<<<ERROR>>>>>>>", err);
    },
  };

  const presenter = getOwnersDetailPresenter(viewHandlers);

  useEffect(() => {
    presenter.present();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    presenter.getOwner(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <div className="owners-detail-screen">
      {JSON.stringify(owner) !== "{}" ? (
        <div className="owners-detail-main-container">
          <TableContainer
            className="owners-detail-table-container"
            component={Paper}
          >
            <Table className="owners-detail-table">
              <TableHead className="owners-detail-table-header">
                <TableRow>
                  <TableCell
                    align="center"
                    colSpan={2}
                    className="owners-detail-table-title"
                  >
                    Detalles del dueño
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody className="owners-detail-table-body">
                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    Nombre:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {owner.fullname}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    DNI:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {owner.document_number}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    Email:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {owner.email}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    Teléfono:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {owner.phone}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    Dirección:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {`${owner.address}, ${owner.locality}`}
                  </TableCell>
                </TableRow>

                <TableRow>
                  <TableCell className="owners-detail-cell-title">
                    Provincia - País:
                  </TableCell>
                  <TableCell className="owners-detail-cell-data">
                    {`${owner.province} - ${owner.country}`}
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      ) : (
        <div className="loading">
          <div className="loader-container">
            <div className="loader-img-container">
              <img width={275} src={logo} alt="logo" />
            </div>
            <Loader />
          </div>
        </div>
      )}
      <div className="owners-detail-back-btn-container">
        <CustomButton
          onClick={() => navigate(-1)}
          variant="outlined"
          color="#FFC107"
          textColor={"black"}
        >
          Volver
        </CustomButton>
      </div>
    </div>
  );
}

export default OwnersDetail;
