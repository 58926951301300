import axios from "axios";

export const HttpEditRateGateway = () => {
  return {
    updateRate: async (data) => {
      const {
        id,
        branch_office_id,
        dayWeek,
        partDay,
        typeCut,
        flag,
        intervalPrice,
        maximum,
        comments,
      } = data;

      const body = {
        id: Number(id),
        branch_office_id: Number(branch_office_id),
        dayWeek: Number(dayWeek),
        partDay: Number(partDay),
        typeCut,
        flag: Number(flag),
        intervalPrice: Number(intervalPrice),
        maximum: Number(maximum),
        comments,
      };
      return axios.put(
        `${process.env.REACT_APP_TB_SOFTWARE_BASE_URL}/business/rate/update`,
        body
      );
    },
  };
};
