import {
  Box,
  Card,
  Divider,
  Grid,
  Rating,
  Stack,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "./styles.scss";
import { CustomButton } from "../../../../components/UI/CustomButton";

const labels = {
  0.5: "Insuficiente",
  1: "Insuficiente+",
  1.5: "Malo",
  2: "Malo+",
  2.5: "Bueno",
  3: "Bueno+",
  3.5: "Muy Bueno",
  4: "Muy Bueno+",
  4.5: "Excellente",
  5: "Excellente+",
};

function getLabelText(value) {
  return `${value} Star${value !== 1 ? "s" : ""}, ${labels[value]}`;
}

const BarbersRating = ({ barber }) => {
  const [qualification, setQualification] = useState(2.5);
  const [hover, setHover] = useState(-1);

  const submitQuali = () => {
    console.log("DATOS PARA ENVIAR", {
      ...barber,
      qualification: qualification,
    });
  };

  return (
    <Box className="barbers-rating-main-container">
      <Grid container className="barbers-rating-grid">
        <Grid item xs={12} lg={12} className="barbers-rating-grid-item">
          <Card className="barbers-data-card">
            <Stack
              direction="column"
              justifyContent="center"
              alignItems="stretch"
              className="barbers-data-stack"
              spacing={2}
              flexWrap="wrap"
            >
              <div className="barbers-data-container">
                <div className="data">
                  <div className="data-title">Nombre</div>
                  <div className="data-value">{`${barber.name} ${barber.lastname}`}</div>
                </div>
                <Divider orientation="vertical" flexItem />
                <div className="data">
                  <div className="data-title">Nombre de usuario</div>
                  <div className="data-value">{`${barber.username}`}</div>
                </div>
              </div>

              <Divider variant="middle" className="separator" />

              <div className="barbers-rating-container">
                <div className="rating">
                  <h4 className="rating-title">Calificación actual</h4>
                  <div className="data-rating">
                    <Rating
                      className="barber-qualification"
                      name="product-qualification"
                      defaultValue={3}
                      /* defaultValue={
                          product.qualification ? product.qualification : null
                        } */
                      readOnly
                      precision={0.5}
                      size="medium"
                    />
                    <Typography variant="body2" className="quali-title">
                      3
                    </Typography>
                  </div>
                </div>
              </div>
              <Divider variant="middle" className="separator" />
              <div className="barbers-rating-container">
                <div className="rating">
                  <h4 className="rating-title">Contanos que te pareció</h4>
                  <div className="data-rating-input">
                    <Rating
                      className="barber-qualification-input"
                      name="product-qualification"
                      value={qualification}
                      getLabelText={getLabelText}
                      onChange={(evt, newValue) => setQualification(newValue)}
                      onChangeActive={(event, newHover) => {
                        setHover(newHover);
                      }}
                      precision={0.5}
                      size="large"
                    />
                    {qualification !== null && (
                      <Typography variant="body2" className="quali-title-input">
                        {labels[hover !== -1 ? hover : qualification]}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>

              <Divider variant="middle" className="separator" />

              <div className="barbers-buttons-container">
                <CustomButton
                  onClick={() => submitQuali()}
                  variant="outlined"
                  color="#FFC107"
                  textColor={"black"}
                >
                  Compartir
                </CustomButton>
              </div>
            </Stack>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BarbersRating;
